import React, { useState, useRef, useEffect } from "react";

interface FilterModalProps {
  isOpen: boolean;
  onClose: () => void;
  titles: string[];
  onFilterApply: (selectedTitles: string[]) => void; 
}

const FilterModal: React.FC<FilterModalProps> = ({ isOpen, onClose, titles, onFilterApply }) => {
  const [selectedTitles, setSelectedTitles] = useState<string[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleSelection = (title: string) => {
    setSelectedTitles((prev) =>
      prev.includes(title) ? prev.filter((t) => t !== title) : [...prev, title]
    );
  };

  const removeTitle = (title: string) => {
    setSelectedTitles((prev) => prev.filter((t) => t !== title));
  };

  const clearFilters = () => {
    setSelectedTitles([]);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  if (!isOpen) return null;

  const handleApplyFilters = () => {
    onFilterApply(selectedTitles);
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white w-[800px] rounded-2xl shadow-xl p-8 relative">
        <button
          onClick={onClose}
          className="absolute top-6 right-6 text-gray-500 hover:text-gray-700 bg-white"
        >
          ✕
        </button>

        <h2 className="text-xl font-semibold text-gray-800 mb-6">Filtrar mensagens</h2>

        <div className="mb-6">
          <label htmlFor="channels" className="block text-sm font-medium text-gray-700 mb-3">
            Canais
          </label>
          <div className="flex gap-4 items-center">
            <div className="relative flex-1" ref={dropdownRef}>
              <div
                className="border border-gray-300 rounded-lg p-2 bg-white cursor-pointer flex justify-between items-center min-h-[40px]"
                onClick={() => setIsDropdownOpen((prev) => !prev)}
              >
                <div className="flex flex-wrap gap-2">
                  {selectedTitles.map((title) => (
                    <span
                      key={title}
                      className="flex items-center gap-2 border border-green-500 text-green-700 text-sm px-2 py-1 rounded h-[24px] leading-[24px]"
                    >
                      {title}
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          removeTitle(title);
                        }}
                        className="text-red-500 hover:text-red-700 p-0 m-0 flex items-center justify-center bg-transparent focus:outline-none"
                        style={{ fontSize: "14px", lineHeight: "1", padding: "0" }}
                      >
                        ✕
                      </button>
                    </span>
                  ))}
                </div>
                <span className="text-gray-400">{isDropdownOpen ? "▲" : "▼"}</span>
              </div>

              {isDropdownOpen && (
                <div className="absolute z-10 w-full bg-white border border-gray-300 rounded-lg shadow-md mt-2">
                  {titles.map((title) => (
                    <div
                      key={title}
                      className="flex items-center gap-3 px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    >
                      <input
                        type="checkbox"
                        id={`checkbox-${title}`}
                        className="form-checkbox"
                        checked={selectedTitles.includes(title)}
                        onChange={() => toggleSelection(title)}
                      />
                      <label htmlFor={`checkbox-${title}`} className="text-sm text-gray-700">
                        {title}
                      </label>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <button
              onClick={clearFilters}
              className="bg-gray-100 text-gray-600 text-sm px-4 py-2 rounded-lg hover:bg-gray-200"
            >
              Limpar filtros
            </button>
          </div>
        </div>

        <div className="bg-gray-50 text-sm text-gray-600 px-4 py-3 rounded-lg mb-6">
          {selectedTitles.length > 0
            ? `${selectedTitles.length} canal(is) selecionado(s)`
            : "Nenhum canal selecionado"}
        </div>

        <div className="flex justify-end gap-4">
          <button
            onClick={onClose}
            className="text-gray-500 text-sm px-5 py-2 bg-white hover:underline"
          >
            Cancelar
          </button>
          <button
            className="bg-orange-400 text-white text-sm px-5 py-2 rounded-lg hover:bg-orange-300"
            onClick={handleApplyFilters}
          >
            Filtrar
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterModal;
