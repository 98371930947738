import { Typography } from '@mui/material';
import { useRef, useState } from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';
import { FaCheck } from 'react-icons/fa';
import * as Styled from './styles';
import { IUpload } from './types';

interface IUploadContractProps extends IUpload {
    error?: unknown;
    fileUrl?: string;
    disabled?: boolean;
}

const UploadContract: React.FC<IUploadContractProps> = ({ onUpload, hasFile, error, disabled, fileUrl }: IUploadContractProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [files, setFiles] = useState<File[]>([]);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const fileList = e.target.files;
        if (!fileList) return;

        // Converte FileList em um array e atualiza o estado
        const newFiles: File[] = Array.from(fileList);
        setFiles((prevFiles) => [...prevFiles, ...newFiles]);

        // Passa todos os arquivos para o callback onUpload
        onUpload([...files, ...newFiles]);
    };

    const removeFile = (index: number) => {
        const updatedFiles = files.filter((_, i) => i !== index);
        setFiles(updatedFiles);
        onUpload(updatedFiles);
    };

    return (
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }} className="font-main">
            <Styled.PreviaContainer onClick={() => inputRef.current?.click()}>
                <Styled.PreviaDescription>
                    <input
                        disabled={disabled ? true : false}
                        type="file"
                        style={{ display: 'none' }}
                        ref={inputRef}
                        onChange={handleFileChange}
                        multiple 
                    />
                    <Typography
                        variant="body2"
                        color="#F28E00"
                        className="font-main"
                        sx={{ fontFamily: 'Poppins' }}
                    >
                        Escolher arquivo(s)
                    </Typography>
                </Styled.PreviaDescription>
            </Styled.PreviaContainer>

            <Styled.FileUpload>
                {files.length > 0 ? (
                    <div style={{display: 'flex', alignItems: 'center', gap: '4px'}}>
                        {files.map((file, index) => (
                            <div
                                key={index}
                                style={{
                                    display: 'flex',
                                    gap: '10px',
                                    alignItems: 'center',
                                    marginBottom: '8px',
                                }}
                            >
                                <FaCheck />
                                <Typography variant="body2">{file.name}</Typography>
                                <AiFillCloseCircle
                                    color="#E86060"
                                    onClick={() => removeFile(index)}
                                    data-cy="cancel-file-btn"
                                />
                            </div>
                        ))}
                    </div>
                ) : (
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{ fontFamily: 'Poppins' }}
                    >
                        Nenhum arquivo selecionado
                    </Typography>
                )}
            </Styled.FileUpload>
        </div>
    );
};

export default UploadContract;
