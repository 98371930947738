import styled from 'styled-components';

export const Container = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
    backdrop-filter: blur(2px);
    animation: modalOpen 0.5s ease-in forwards;

    @keyframes modalOpen {
        from {
            backdrop-filter: blur(0);
            background-color: rgba(0, 0, 0, 0);
        }
        to {
            backdrop-filter: blur(2px);
            background-color: rgba(0, 0, 0, 0.4);
        }
    }
`;

export const Modal = styled.div`
    position: relative;
    border-radius: 5px;
    padding: 24px;
    width: 100%;
    max-width: 648px;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const Close = styled.button`
    display: flex;
    position: absolute;
    right: 15px;
    cursor: pointer;
    border: none;
    background: none;
    padding: 0;
    svg {
        width: 20px;
        height: 20px;
    }
`;

export const IconContainer = styled.span`
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    min-height: 72px;
    border-radius: 50%;
    svg {
        width: 45px;
        height: 45px;
    }
`;

export const Title = styled.p`
    font-size: 18px;
    margin-top: 12px;
`;

export const Description = styled.p`
    font-size: 16px;
    line-height: 27px;
    margin-top: 12px;
    font-family: 'Poppins';
    strong {
        font-weight: 400;
    }
`;

export const ConfirmContainer = styled.div`
    width: 100%;
    padding: 37px 0px 0 0;
    text-align: left;
`;

export const ConfirmLabel = styled.span`
    display: block;
    font-size: 16px;
    margin-bottom: 8px;
`;

export const ConfirmInput = styled.input`
    width: '100%';
    padding: '8px 10px';
    height: '40px';
    width: 100%;
    border: 1px solid;
    border-radius: 4px;
    padding: 10px;
`
;

export const OptionsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 36px;
    padding: 0 0 0 15px;
    div[disabled=''] {
        opacity: 0.5;
    }
    gap: 16px;
`;

export const CoontainerHeader = styled.div`
    display: flex;
    align-items: center;

`

export const ContainerTitle = styled.div`
    color: #404040;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
`

export const ContainerDescription = styled.div`
    color: #404040;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;

    span {
        font-weight: 700;
    }

`