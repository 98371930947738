import { useEffect, useState, useRef, useCallback } from "react";
import Header from "../../../components/Header";
import Input from "../../../components/Input";
import SelectEnterprise from "../../../components/SelectEnterprise";
import { ChatBubbleOutline, MoreVert, Send, FilterAlt, AttachFile } from "@mui/icons-material";
import MessagesAPI from "../../../api/messages/messages";
import { Spinner } from "../../../components/Spinner";
import FilterModal from "../../../components/FilterModal/FilterModal";
import React from "react";

type Conversation = {
  ChatThreadsId: number;
  ChatTopicId: number;
  Title: string;
  Active?: boolean;
  OwnerUserId?: number;
  Name: string;
  LatestCreatedAt: string;
  Message: string;
};

type MessageType = {
  Message: string;
  Time: string;
  IsSender: boolean;
};

export default function NewChannel() {
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [selectedConversation, setSelectedConversation] = useState<Conversation | null>(null);
  const [messages, setMessages] = useState<MessageType[]>([]);
  const [message, setMessage] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [selectActive, setSelectActive] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingMoreMessages, setIsLoadingMoreMessages] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const [availableTitles, setAvailableTitles] = useState<string[]>([]);
  const [selectedFilterTitles, setSelectedFilterTitles] = useState<string[]>([]);
  
  // Estado para o termo de pesquisa
  const [searchTerm, setSearchTerm] = useState<string>("");

  // Estado para indicar se as mensagens da conversa selecionada estão carregando
  const [isLoadingMessages, setIsLoadingMessages] = useState(false);

  const userId = Number(localStorage.getItem("userId"));
  const messagesContainerRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = useCallback(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  }, []);

  const formatTime = (dateString: string) => {
    const date = new Date(dateString);
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  const truncateMessage = (msg: string | null | undefined, maxLength = 50) => {
    if (!msg) return "";
    if (msg.length <= maxLength) return msg;
    return msg.slice(0, maxLength) + "...";
  };

  const loadConversations = async () => {
    const networkUnitId = localStorage.getItem("selectedNetwork");
    if (!networkUnitId) {
      console.error("NetworkUnitId não encontrado.");
      return;
    }
  
    setIsLoading(true);
    try {
      const data = await MessagesAPI.listAdminChannels(Number(networkUnitId));
  
      const adjustedData: Conversation[] = data.map((conv: any) => ({
        ChatThreadsId: conv.ChatThreads,
        ChatTopicId: conv.ChatTopicId,
        Title: conv.Title as string,
        Active: conv.Active ?? true,
        OwnerUserId: conv.OwnerUserId,
        Name: conv.Name as string,
        LatestCreatedAt: conv.LatestCreatedAt as string,
        Message: conv.Message as string,
      }));
  
      setConversations(adjustedData || []);
      const uniqueTitles = Array.from(new Set(adjustedData.map((c) => c.Title)));
      setAvailableTitles(uniqueTitles);
  
    } catch (error) {
      console.error("Erro ao carregar conversas:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const loadMessages = async (chatThreadsId: number, offset: number, prepend = false) => {
    try {
      const container = messagesContainerRef.current;
      let oldScrollHeight = 0;
      if (container && prepend) {
        oldScrollHeight = container.scrollHeight;
      }

      const data = await MessagesAPI.getPaginatedMessages(chatThreadsId, offset);
      if (!data || data.length === 0) {
        setHasMore(false);
        setIsLoadingMoreMessages(false);
        return;
      }

      const formattedMessages = data.map((msg: any) => ({
        Message: msg.Message,
        Time: formatTime(msg.CreatedAt),
        IsSender: msg.UserId === userId
      }));

      setMessages((prev) => {
        if (prepend) {
          return [...formattedMessages, ...prev];
        } else {
          return [...prev, ...formattedMessages];
        }
      });

      if (!prepend) {
        setTimeout(() => {
          scrollToBottom();
        }, 0);
      } else if (container) {
        setTimeout(() => {
          const newScrollHeight = container.scrollHeight;
          container.scrollTop = newScrollHeight - oldScrollHeight;
        }, 0);
      }

      setIsLoadingMoreMessages(false);
    } catch (error) {
      console.error("Erro ao carregar mensagens:", error);
      setIsLoadingMoreMessages(false);
    }
  };

  const handleSelectedHotelChange = (selectedHotelId: number | null) => {
    setSelectActive(selectedHotelId);
  };

  const handleSelectConversation = async (conversation: Conversation) => {
    if (!conversation) {
      console.error("Conversa inválida selecionada!");
      return;
    }

    setSelectedConversation(conversation);
    setMessages([]);
    setPage(1);
    setHasMore(true);
    setIsLoadingMessages(true); // Inicia o loading de mensagens
    await loadMessages(conversation.ChatThreadsId, 1);
    setIsLoadingMessages(false); // Finaliza o loading de mensagens
  };

  const handleSendMessage = async () => {
    if (!message.trim() || !selectedConversation) {
      console.error("Mensagem vazia ou nenhuma conversa selecionada!");
      return;
    }

    const chatThreadsId = selectedConversation.ChatThreadsId;

    try {
      await MessagesAPI.sendMessage(
        chatThreadsId,
        message,
        userId,
        selectedConversation.ChatTopicId
      );

      setMessages((prev) => [
        ...prev,
        { Message: message, Time: formatTime(new Date().toISOString()), IsSender: true },
      ]);
      setMessage("");
      setTimeout(() => {
        scrollToBottom();
      }, 0);
    } catch (error) {
      console.error("Erro ao enviar mensagem:", error);
    }
  };

  useEffect(() => {
    const networkUnitId = localStorage.getItem("selectedNetwork");
    if (networkUnitId) {
      setSelectActive(Number(networkUnitId));
    }
  }, []);

  useEffect(() => {
    if (selectActive !== null) {
      loadConversations();
      setSelectedConversation(null);
      setMessages([]);
    }
  }, [selectActive]);

  const handleScroll = () => {
    const container = messagesContainerRef.current;
    if (!container || isLoading || !hasMore || isLoadingMoreMessages) return;
    if (container.scrollTop === 0 && selectedConversation) {
      const nextPage = page + 1;
      setPage(nextPage);
      setIsLoadingMoreMessages(true);
      loadMessages(selectedConversation.ChatThreadsId, nextPage, true);
    }
  };

  const handleFilterApply = (selectedTitles: string[]) => {
    setSelectedFilterTitles(selectedTitles);
  };

  // Primeiro filtra por título
  let filteredConversations = selectedFilterTitles.length > 0
    ? conversations.filter((c) => selectedFilterTitles.includes(c.Title))
    : conversations;

  // Depois filtra por termo de pesquisa (por exemplo, pesquisando no campo Name)
  if (searchTerm.trim() !== "") {
    filteredConversations = filteredConversations.filter((c) =>
      c.Name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }

  return (
    <div className="flex flex-col w-full h-screen bg-gray-50 p-8 overflow-hidden">
      <div className="flex w-full mb-8">
        <div className="w-1/2">
          <Header
            title="Mensagens"
            subTitle="Visualize um histórico de conversa e responda aos usuários."
            arrowIcon={false}
          />
        </div>
        <div className="flex flex-col items-end gap-5 w-1/2">
          <SelectEnterprise onSelectedHotelChange={handleSelectedHotelChange} />
        </div>
      </div>

      <div className="flex gap-8 h-full overflow-hidden">
        <aside className="w-1/3 bg-white shadow-lg rounded-lg p-6 flex flex-col h-full overflow-hidden">
          <div className="mb-6">
            <Input 
              label="" 
              placeholder="Pesquisar" 
              type="text" 
              icon={true}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <button
            className="flex items-center gap-2 border border-orange-400 bg-gray-50 text-orange-600 px-4 py-2 rounded-lg mb-6"
            onClick={() => setIsFilterModalOpen(true)}
          >
            <span className="text-sm font-medium">
              {`Filtrar canais${selectedFilterTitles.length > 0 ? ` (${selectedFilterTitles.length})` : ""}`}
            </span>
            <FilterAlt style={{ color: "#F28E00" }} className="h-5 w-5" />
          </button>


          {isLoading ? (
            <div className="flex justify-center items-center flex-1">
              <Spinner />
            </div>
          ) : (
            <div className="flex-1 overflow-y-auto">
              {filteredConversations.map((conversation) => (
                <div
                  key={conversation.ChatThreadsId}
                  className={`p-4 border-b cursor-pointer hover:bg-gray-50 transition-colors ${
                    selectedConversation?.ChatThreadsId === conversation.ChatThreadsId ? "bg-gray-100" : "bg-white"
                  }`}
                  onClick={() => handleSelectConversation(conversation)}
                >
                  <div className="flex justify-between items-center mb-1">
                    <div className="flex items-center gap-2">
                      <span className="font-bold text-gray-900">{conversation.Name}</span>
                      <span className="text-xs bg-gray-100 text-gray-600 px-2 py-[2px] rounded-full">
                        {conversation.Title}
                      </span>
                    </div>
                    <span className="text-sm text-gray-500">{formatTime(conversation.LatestCreatedAt)}</span>
                  </div>
                  <p className="text-sm text-gray-600">
                    {truncateMessage(conversation.Message, 50)}
                  </p>
                </div>
              ))}
            </div>
          )}
        </aside>

        <section className="flex-1 bg-white shadow-lg rounded-lg p-6 flex flex-col h-full overflow-hidden">
          {selectedConversation && !isLoading ? (
            <>
              <div className="flex justify-between items-center border-b pb-4 mb-4 flex-none">
                <div className="flex items-center gap-2">
                  <h2 className="font-bold text-gray-800">{selectedConversation?.Name}</h2>
                  <span className="text-xs bg-gray-100 text-gray-600 px-2 py-[2px] rounded-full">
                    {selectedConversation?.Title}
                  </span>
                </div>
                <MoreVert className="text-gray-700 cursor-pointer" />
              </div>

              {isLoadingMessages ? (
                <div className="flex-1 flex justify-center items-center">
                  <Spinner />
                </div>
              ) : (
                <div
                  className="relative flex-1 overflow-y-auto space-y-4 mb-4"
                  ref={messagesContainerRef}
                  onScroll={handleScroll}
                >
                  {isLoadingMoreMessages && (
                    <div className="flex justify-center items-center my-2">
                      <Spinner />
                    </div>
                  )}
                  {messages.map((messageObj, index) => (
                    <div
                      key={index}
                      className={`flex ${messageObj.IsSender ? "justify-end" : "justify-start"}`}
                    >
                      <div
                        className={`relative p-3 rounded-lg max-w-[75%] ${
                          messageObj.IsSender ? "bg-[#2E406B] text-white" : "bg-gray-100 text-gray-800"
                        }`}
                      >
                        <p className="text-sm">{messageObj.Message}</p>
                        <div className="flex items-center justify-end gap-1 mt-1">
                          <span className="text-xs text-gray-400">{messageObj.Time}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              <footer className="flex items-center gap-2 bg-gray-50 px-4 py-3 flex-none">
                <div className="flex items-center flex-1 bg-white border border-gray-300 rounded-full px-4 py-2">
                  <Input
                    label=""
                    placeholder="Digite uma mensagem"
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                  <AttachFile className="text-gray-400 hover:text-blue-500 cursor-pointer" />
                </div>
                <button
                  onClick={handleSendMessage}
                  className="bg-[#2E406B] p-3 rounded-full flex items-center justify-center shadow-md text-white"
                >
                  <Send />
                </button>
              </footer>
            </>
          ) : (
            !isLoading && (
              <div className="flex flex-col justify-center items-center text-center flex-1">
                <ChatBubbleOutline style={{ fontSize: 60, color: "#9ca3af" }} className="mb-6" />
                <h2 className="text-lg font-bold text-gray-800 mb-2">Boas-vindas</h2>
                <p className="text-sm text-gray-600">
                  Para começar a enviar mensagens, primeiro selecione uma conversa na lista ao lado.
                </p>
              </div>
            )
          )}

          {isLoading && (
            <div className="flex flex-col justify-center items-center text-center flex-1">
              <Spinner />
            </div>
          )}
        </section>
      </div>
      <FilterModal
        isOpen={isFilterModalOpen}
        onClose={() => setIsFilterModalOpen(false)}
        titles={availableTitles}
        onFilterApply={handleFilterApply}
      />
    </div>
  );
}
