import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Tooltip from "@mui/material/Tooltip";

interface IPageSize {
  pageSize: number;
  setPageSize(e: number): void;
}

/**
 * Componente responsável por apresentar as opções de quantidade de linhas
 * listadas no datatables.
 *
 * @returns
 */
export default function PageSize({ pageSize, setPageSize }: IPageSize) {
  const handleChange = (event: SelectChangeEvent) => {
    setPageSize(parseInt(event.target.value));
  };

  return (
    <Box
      sx={{
        minWidth: 50,
      }}
    >
      <Tooltip title="Itens por página" placement="left">
        <FormControl fullWidth size="small">
          <Select
            style={{
              height: "30px",
            }}
            value={pageSize.toString()}
            onChange={handleChange}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </FormControl>
      </Tooltip>
    </Box>
  );
}
