import { useState, useEffect } from 'react';
import Modal from 'react-modal'
import icon1 from '../../assets/icons/main-icon.svg'; // Ícone padrão
import Map1 from '../../assets/menu/map-1.svg';
import Map from '../../assets/menu/map.svg';
import Map2 from '../../assets/menu/map1.svg';
import Mapa from '../../assets/menu/mapa.svg';
import Momudules from '../../assets/menu/modules.svg';
import Money1 from '../../assets/menu/money-1.svg';
import Money2 from '../../assets/menu/money.svg';
import Phone from '../../assets/menu/phone.svg';
import RoomKey from '../../assets/menu/room-key.svg';
import RoomService from '../../assets/menu/room-service.svg';
import Room from '../../assets/menu/room.svg';
import SafeBox from '../../assets/menu/safe-box.svg';
import Search from '../../assets/menu/search.svg';
import Config from '../../assets/menu/settings.svg';
import Tv from '../../assets/menu/smart-tv.svg';
import Support from '../../assets/menu/support.svg';
import Upload from '../../assets/menu/upload.svg';

Modal.setAppElement('#root');

interface IconSelectorProps {
  onIconSelect: (icon: React.ReactNode) => void;
  initialIconUrl?: string;  // Propriedade opcional para passar a URL inicial do ícone
}

const IconSelector: React.FC<IconSelectorProps> = ({ onIconSelect, initialIconUrl }) => {
  // Use a URL do ícone inicial ou um ícone padrão se não houver URL
  const [selectedIcon, setSelectedIcon] = useState<React.ReactNode>(
    initialIconUrl ? <img src={initialIconUrl} alt="Ícone selecionado" width={24} /> : <img src={icon1} alt="Ícone selecionado" width={24} />
  );
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const icons = [
    { name: 'Padrão', src: icon1 },
    { name: 'Mapa', src: Map1 },
    { name: 'Mapa 2', src: Map },
    { name: 'Mapa 3', src: Map2 },
    { name: 'Bússola', src: Mapa },
    { name: 'Tabela', src: Momudules },
    { name: 'Dinheiro', src: Money1 },
    { name: 'Dinheiro 2', src: Money2 },
    { name: 'Telefone', src: Phone },
    { name: 'Chave do quarto', src: RoomKey },
    { name: 'Serviço de quarto', src: RoomService },
    { name: 'Quarto', src: Room },
    { name: 'Caixa', src: SafeBox },
    { name: 'Pesquisa', src: Search },
    { name: 'Configuração', src: Config },
    { name: 'Ajuda', src: Support },
    { name: 'Tv', src: Tv },
    { name: 'Upload', src: Upload },
  ];

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const handleIconClick = (src: string) => {
    const iconElement = <img src={src} alt="Ícone selecionado" width={24} />;
    setSelectedIcon(iconElement);
    onIconSelect(iconElement);  
    closeModal();
  };

  // UseEffect opcional para quando a URL do ícone mudar dinamicamente (se necessário)
  useEffect(() => {
    if (initialIconUrl) {
      setSelectedIcon(<img src={initialIconUrl} alt="Ícone selecionado" width={24} />);
    }
  }, [initialIconUrl]);

  return (
    <div className='flex flex-col gap-2 font-main'>
      <label htmlFor="" className='font-medium text-[#404040]'>Ícone</label>
      <div className="flex items-center">
        <div className="mr-4 text-gray-500 px-2 py-[6px] rounded-sm border border-[#E3E7FF]">
          {selectedIcon}
        </div>
        <button
          onClick={openModal}
          className="bg-[#FCFCFA] text-[#F28E00] 
          px-6 py-2 rounded-md border border-[#E3E7FF] flex flex-col items-start
          w-40
          text-[13px]
          "
        >
          Alterar ícone
        </button>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Selecionar Ícone"
        className="modal"
        overlayClassName="overlay"
      >
        <h2 className="text-xl mb-4">Selecionar Ícone</h2>
        <div className="grid grid-cols-3 gap-4">
          {icons.map((item, index) => (
            <div
              key={index}
              className="flex flex-col items-center cursor-pointer"
              onClick={() => handleIconClick(item.src)}
            >
              <img src={item.src} alt={item.name} width={50} /> 
              <p>{item.name}</p>
            </div>
          ))}
        </div>
        <button
          onClick={closeModal}
          className="mt-4 bg-gray-300 text-black px-4 py-2 rounded"
        >
          Cancelar
        </button>
      </Modal>
    </div>
  );
};

export default IconSelector;
