import { FaSearch, FaEye, FaEyeSlash } from 'react-icons/fa';
import { useState } from 'react';
import { InputProps } from "./types";

export default function Input({ label, placeholder, type, value, onChange, register, errors, icon, password }: InputProps) {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="flex flex-col gap-2 font-main w-full">
      <label htmlFor="" className="text-[#404040] font-medium text-base">
        {label}
      </label>
      <div className="relative w-full">
        <input
          className={`rounded p-2 border border-[#E3E7FF] outline-none bg-white placeholder:text-[#8A97B1] w-full ${icon ? 'pr-10' : ''}`}
          type={password ? (showPassword ? 'text' : 'password') : type}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          {...register}
        />
        {icon && (
          <FaSearch className="absolute right-3 top-1/2 transform -translate-y-1/2 text-[#8A97B1]" />
        )}
        {password && (
          <div className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer" onClick={togglePasswordVisibility}>
            {showPassword ? <FaEyeSlash className="text-[#8A97B1]" /> : <FaEye className="text-[#8A97B1]" />}
          </div>
        )}
      </div>
      {errors && <p className="text-red-500 text-sm">{errors.message}</p>}
    </div>
  );
}
