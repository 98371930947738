import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import ActionsMenu from "../../components/Table/ActionsMenu";
import { ContinerSwitch } from "../Channels/Channels/styles";

interface RowData {
  userNotifications: string;
  createdAt: string;
  title: string;
  name: string;
  category: number | string;
}

export function getColumns(
  toggleIsEdit?: ((row: RowData) => void) | null,
  deleteHotelChain?: ((row: RowData) => void) | null,
  // exist?: boolean,
  // toggleStatus?: ((row: { Active: boolean }) => void) | null

) {
  const columns = [
    {
      name: "Título",
      selector: (row: RowData) => <span>{row.title}</span>,
    },
    {
      name: "Publicação",
      selector: (row: RowData) => {
        const createdAtDate = new Date(row.createdAt as string);
        const formattedDate = `${createdAtDate.getDate()}/${createdAtDate.getMonth() + 1}/${createdAtDate.getFullYear()}`;
        return <span>{formattedDate}</span>;
      },
    },
    {
      name: "Disponibilizado para",
      selector: (row: RowData) => <span>{row.userNotifications as string}</span>,
    },
    // {
    //   name: "Status",
    //   selector: (row: { Active: boolean; Id: string }) => {
    //     const handleChange = async () => {
    //       if (row && toggleStatus) {
    //         await toggleStatus(row);
    //       }
    //     };
    //     return (
    //       <ContinerSwitch>
    //         <FormGroup>
    //           <FormControlLabel
    //             control={
    //               <Switch
    //                 checked={row.Active}
    //                 edge="start"
    //                 onChange={handleChange}
    //               />
    //             }
    //             label={row.Active ? "Ativo" : "Inativo"}
    //           />
    //         </FormGroup>
    //       </ContinerSwitch>
    //     );
    //   },
    // },
    {
      name: "Ações",
      width: "60px",
      cell: (row: RowData) => (
        <ActionsMenu
          onEdit={toggleIsEdit ? () => toggleIsEdit(row) : null}
          onDelete={deleteHotelChain ? () => deleteHotelChain(row) : null}
        />
      ),
    },
  ];

  // if (exist) {
  //   columns.splice(2, 0, {
  //     name: "Status",
  //     selector: (row: RowData) => {
  //       return (
  //         <FormGroup>
  //           {row.category === 1 ? (
  //             <FormControlLabel
  //               control={<Switch defaultChecked />}
  //               label="Ativo"
  //             />
  //           ) : (
  //             <FormControlLabel
  //               control={<Switch defaultChecked />}
  //               label="Inativo"
  //             />
  //           )}
  //         </FormGroup>
  //       );
  //     },
  //   });
  // }

  return columns;
}
