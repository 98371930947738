/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { Grid } from "@mui/material";
import { useState } from "react";
import * as Styled from "./styles";
import { IPaginationProps } from "./types";

const Pagination = ({
  pageCount: pgC,
  currentPage,
  setPage,
  setPagePrevious,
}: IPaginationProps) => {
  const [pageInput, setPageInput] = useState<string>("");
  const pageCount = pgC ? pgC : 1;

  const handlePageClick = ({ selected }: { selected: number }) => {
    if (setPage) {
      setPage(selected + 1);
    }
    if (setPagePrevious && currentPage !== undefined) {
      setPagePrevious(currentPage);
    }
  };

  const goPage = () => {
    if (setPage && pageInput) {
      const pageNumber = parseInt(pageInput, 10);

      if (pageNumber > pageCount) {
        setPage(pageCount);
        setPageInput(pageCount.toString());
      } else if (pageNumber < 1) {
        setPage(1);
        setPageInput("1");
      } else {
        setPage(pageNumber);
      }
    }
  };

  return (
    <Grid container flexDirection={"row"} justifyContent={"space-between"}>
      <Grid item xs>
        <Styled.MyPaginate
          previousLabel="< Anterior"
          nextLabel="Próximo >"
          pageCount={pageCount}
          onPageChange={handlePageClick}
          forcePage={currentPage - 1}
          marginPagesDisplayed={2}
          pageRangeDisplayed={2}
        />
      </Grid>
      <Grid item xs>
        <Grid container justifyContent={"flex-end"}>
          <Styled.Page>
            <Styled.TextGoPage>Ir para página</Styled.TextGoPage>
            <Styled.Input
              onChange={(e) => setPageInput(e.currentTarget.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  goPage();
                }
              }}
              value={pageInput}
            />
            <Styled.ButtonGo onClick={goPage}>Ir</Styled.ButtonGo>
          </Styled.Page>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Pagination;
