import styled from 'styled-components';

export const Container = styled.div`
    & > div {
        overflow: hidden;
    }
    & > div > div {
        table-layout: fixed;
        width: 100%;
    }
`;
