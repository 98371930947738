import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Styled from './styles';

function formatBreadcrumbs(pathname: string) {
  return pathname.split('/').filter(x => x).map((name: string) => {
    const breads = name.replace(/-/g, ' ');
    return breads.split('>').map(item => item.trim().charAt(0).toUpperCase() + item.trim().slice(1));
  });
}

function BreadcrumbsPage() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const pathnames = formatBreadcrumbs(pathname);

  // Gera o caminho parcial a cada iteração
  const generatePath = (index: number) => {
    const pathArray = pathname.split('/').filter(x => x);
    return `/${pathArray.slice(0, index + 1).join('/')}`;
  };

  return (
    <div role="presentation">
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<span style={{ color: 'orange' }}>{'>'}</span>}
      >
        {pathnames.map((breadcrumb, index) => (
          <Styled.Container key={index}>
            <Link
              onClick={() => navigate(generatePath(index))}
              underline="hover"
              color="inherit"
            >
              {breadcrumb.map((item, indexOne) => (
                <span key={indexOne} className='font-main'>
                  {item}
                </span>
              ))}
            </Link>
          </Styled.Container>
        ))}
      </Breadcrumbs>
    </div>
  );
}

export default BreadcrumbsPage;
