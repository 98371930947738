import CircleGreen from "../../assets/icons/circle-green.svg";
import CircleRed from "../../assets/icons/circle-red.svg";
import CircleYellow from "../../assets/icons/circle-yellow.svg";
import CircleGray from "../../assets/icons/circle-gray.svg";
import CircleGreenLigth from "../../assets/icons/circle-green-ligth.svg"
import ActionsMenu from '../../components/Table/ActionsMenu';

export function getColumns(
    viewReport?: ((row: undefined) => void) | null,
    toggleIsEdit?: ((row: undefined) => void) | null,
    deleteHotelChain?: ((row: undefined) => void) | null,
) {
    return [
        {
            name: 'Nome',
            selector: (row: { name: string }) => (
                <span>{row.name}</span>
            ),
        },
        {
            name: 'Pontos para resgate',
            selector: (row: { totalPoints: number }) => (
                <span>{row.totalPoints}</span> 
            ),
        },
        {
            name: 'Qtd. de unidades',
            selector: (row: { numberOfUnits: number }) => (
                <span>{row.numberOfUnits}</span>
            ),
        },
        {
            name: 'Status',
            selector: (row: { active: boolean }) => {
              let statusText: string;
              let statusImage: string | null;
              let statusStyle: Record<string, string> = {};
          
              if (row.active) {
                statusText = 'Ativo';
                statusImage = CircleGreen; 
              } else {
                statusText = 'Inativo';
                statusImage = CircleGray;
              }
          
              return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img src={statusImage} alt={statusText} style={{ marginRight: '8px' }} />
                  {statusText}
                </div>
              );
            },
          },
        {
            name: '',
            width: '60px',
            cell: (row: undefined) => (
                <ActionsMenu
                    onView={viewReport ? () => viewReport(row) : null}
                    onEdit={toggleIsEdit ? () => toggleIsEdit(row) : null}
                    onDelete={deleteHotelChain ? () => deleteHotelChain(row) : null}
                />
            ),
        },
    ];
}
