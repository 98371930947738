import React, { useEffect, useRef, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Menu } from "./types";
import { Link, useNavigate } from "react-router-dom";
import UserApi from "../../api/user/user";

interface SideBarMenuProps {
  menus: Menu[];
}

const drawerWidth = 320; 

const AppBar = styled(MuiAppBar)<{ open: boolean }>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function SideBarMenu({ menus }: SideBarMenuProps) {
  const theme = useTheme();
  const [open, setOpen] = useState<boolean>(false);
  const [userName, setUserName] = useState<string>("");
  const navigate = useNavigate();
  const drawerRef = useRef<HTMLDivElement | null>(null);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    navigate("/login");
  };

  async function getDataUser() {
    try {
      const response = await UserApi.listUserData();
      if (response) setUserName(response.Name);
    } catch (error) {
      console.error("Erro ao listar dados do usuário", error);
    }
  }

  function getInitials(name: string | undefined) {
    if (!name) return "";
    const words = name.trim().split(" ");
    if (words.length === 1) {
      return words[0].substring(0, 2).toUpperCase();
    }
    return words[0][0].toUpperCase() + words[1][0].toUpperCase();
  }

  useEffect(() => {
    getDataUser();
  }, []);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (drawerRef.current && !drawerRef.current.contains(event.target as Node)) {
        setOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      {/* AppBar para o estado fechado */}
      <AppBar
        open={false}
        sx={{
          height: "100vh",
          width: "70px",
          position: "relative",
          backgroundColor: "#2E406B",
          display: open ? "none" : "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Toolbar sx={{ padding: 0, minHeight: "auto", width: "100%", flexDirection: "column" }}>
          <IconButton color="inherit" onClick={handleDrawerOpen} sx={{ marginTop: "15px" }}>
            <MenuIcon sx={{ fontSize: "32px", color: "#FFFFFF" }} />
          </IconButton>
          <Box
            sx={{
              background: "#27365B",
              border: "1px solid #FFFFFF",
              borderRadius: "50%",
              width: "32px",
              height: "32px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mt: "20px",
            }}
          >
            <Typography variant="body2" sx={{ color: "#FFF", fontSize: "16px", fontWeight: 500 }}>
              {getInitials(userName)}
            </Typography>
          </Box>
          <Box sx={{ mt: "20px", display: "flex", flexDirection: "column", gap: "20px" }}>
            {menus?.map((menu, index) => (
              <img key={index} src={menu.imageUrl} width={24} height={24} alt={menu.title || ""} />
            ))}
          </Box>
        </Toolbar>
      </AppBar>

      {/* Drawer para o estado aberto */}
      <Drawer
        ref={drawerRef}
        sx={{
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            height: "100%",
            backgroundColor: "#2E406B",
            border: "none",
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        {/* Ícone de 3 linhas no topo quando aberto (aumentando o tamanho do ícone) */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "8px 16px",
            backgroundColor: "#2E406B",
          }}
        >
          <IconButton onClick={handleDrawerClose}>
            <MenuIcon sx={{ fontSize: "50px", color: "#FFF" }} />
          </IconButton>
        </Box>

        {/* Perfil do usuário com background arredondado */}
        <Accordion
          defaultExpanded={false}
          disableGutters
          sx={{
            backgroundColor: "transparent",
            color: "#FFF",
            boxShadow: "none",
            border: "none",
            mb: 2,
            // Aumentar o padding lateral
            padding: "0 16px",
          }}
        >
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon sx={{ color: "#FFF" }} />}
            sx={{
              backgroundColor: "#1C2A4D",
              borderRadius: "8px",
              padding: "8px 12px",
              minHeight: "48px",
              "& .MuiAccordionSummary-content": {
                margin: 0,
              },
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <Box
                sx={{
                  background: "#27365B",
                  border: "1px solid #FFFFFF",
                  borderRadius: "50%",
                  width: "32px",
                  height: "32px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="body2" sx={{ color: "#FFF", fontSize: "16px", fontWeight: 500 }}>
                  {getInitials(userName)}
                </Typography>
              </Box>
              <Typography variant="body2" sx={{ fontSize: "16px", fontWeight: 500 }}>
                {userName}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: "8px 12px" }}>
            <Typography
              sx={{
                color: "#D6D8E1",
                fontSize: "15px",
                cursor: "pointer",
                "&:hover": { textDecoration: "underline", color: "#FFF" },
              }}
              onClick={handleLogout}
            >
              Sair
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/* Lista de menus */}
        {menus.map((menu, index) => {
          const hasSubItems = menu.subTitles && menu.subTitles.length > 0;
          if (hasSubItems) {
            return (
              <Accordion
                key={index}
                disableGutters
                sx={{
                  backgroundColor: "transparent",
                  color: "#FFF",
                  boxShadow: "none",
                  border: "none",
                  mb: 1,
                  padding: "0 16px",
                }}
              >
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon sx={{ color: "#FFF", fontSize: "20px" }} />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                  sx={{
                    minHeight: "48px",
                    padding: "8px",
                    borderRadius: "8px",
                    "&:hover": {
                      backgroundColor: "rgba(255,255,255,0.1)",
                    },
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                    {menu.imageUrl && (
                      <img src={menu.imageUrl} width={20} height={20} alt="" style={{ objectFit: "contain" }} />
                    )}
                    <Typography variant="body2" sx={{ fontSize: "16px", fontWeight: 500 }}>
                      {menu.title}
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails sx={{ display: "flex", flexDirection: "column", gap: "8px", padding: "8px 12px" }}>
                  {menu.subTitles?.map((subTitle, subIndex) => (
                    <Typography
                      key={subIndex}
                      component={Link}
                      to={subTitle.url}
                      sx={{
                        color: "#D6D8E1",
                        fontSize: "15px",
                        textDecoration: "none",
                        borderRadius: "8px",
                        padding: "4px 8px",
                        // Agora hover em branco
                        "&:hover": {
                          backgroundColor: "rgba(255,255,255,0.1)",
                          textDecoration: "underline",
                          color: "#FFF" 
                        },
                      }}
                    >
                      {subTitle.text}
                    </Typography>
                  ))}
                </AccordionDetails>
              </Accordion>
            );
          } else {
            return (
              <Box
                key={index}
                sx={{
                  backgroundColor: "transparent",
                  color: "#FFF",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  padding: "8px 24px", // Mais padding lateral
                  mb: 1,
                  borderRadius: "8px",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "rgba(255,255,255,0.1)",
                  },
                }}
                onClick={() => {
                  if (menu.url) navigate(menu.url);
                }}
              >
                {menu.imageUrl && (
                  <img src={menu.imageUrl} width={20} height={20} alt="" style={{ objectFit: "contain" }} />
                )}
                <Typography variant="body2" sx={{ fontSize: "16px", fontWeight: 500 }}>
                  {menu.title}
                </Typography>
              </Box>
            );
          }
        })}
      </Drawer>
    </Box>
  );
}
