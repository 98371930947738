import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from './routes/AppRoutes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <Router>
      <div className="w-screen p-0">
        <AppRoutes />
        <ToastContainer style={{ zIndex: 9999 }} />
      </div>
    </Router>
  );
}

export default App;
