import React from 'react'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { EditorContainer, StyledLabel } from './styles'

type FeedbackEditorProps = {
  label?: string
  value: string
  onChange: (html: string) => void
  disabled?: boolean
}

export const TextEditor: React.FC<FeedbackEditorProps> = ({
  label,
  value,
  onChange,
  disabled
}) => {
  const Size = Quill.import('formats/size')
  Size.whitelist = ['small', 'medium', 'large']
  Quill.register(Size, true)

  // Add fonts to whitelist and register them
  const Font = Quill.import('formats/font')
  Font.whitelist = ['poppins']
  Quill.register(Font, true)

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image'
  ]

  const modules = {
    toolbar: {
      container: '#toolbar'
    },
    clipboard: {
      matchVisual: false
    }
  }

  return (
    // <EditorContainer disabled={disabled}>
    <EditorContainer disabled={Boolean(disabled)}>
      {label && <StyledLabel>{label}</StyledLabel>}
      <div id="toolbar">
        <span className="ql-formats">
          <button className="ql-header" value={1}>
            <p>img 1</p>
          </button>
          <button className="ql-header" value={2}>
            <p>img 2</p>
          </button>
          <select className="ql-font" defaultValue="arial">
            <option value="poppins">Poppins</option>
          </select>
          <select className="ql-size" defaultValue="medium">
            <option value="small">Pequeno</option>
            <option value="medium">Normal</option>
            <option value="large">Grande</option>
          </select>
        </span>
        <span className="ql-formats">
          <button className="ql-bold" />
          <button className="ql-italic" />
          <button className="ql-underline" />
          <button className="ql-strike" />
        </span>
        <span className="ql-formats">
          <button className="ql-list" value="ordered" />
          <button className="ql-list" value="bullet" />
          <button className="ql-indent" value="-1" />
          <button className="ql-indent" value="+1" />
        </span>
        <span className="ql-formats">
          <button className="ql-link" />
          <button className="ql-image" />
        </span>
      </div>
      <ReactQuill
        value={value}
        onChange={html => onChange(html)}
        theme={'snow'}
        formats={formats}
        modules={modules}
        readOnly={Boolean(disabled)}
      />
    </EditorContainer>
  )
}

export default TextEditor
