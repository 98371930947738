import styled from 'styled-components'

type ContainerProps = {
  disabled: boolean
}

export const EditorContainer = styled.div<ContainerProps>`
  .ql-container {
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    background: #fff;
  }

  .ql-snow.ql-toolbar {
    display: block;
    background: #fff;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
    display: ${props => props.disabled && 'none'};
  }

  .ql-editor {
    min-height: 18em;
    font-family: 'Poppins';
  }

  .ql-toolbar.ql-snow {
    border-bottom: 0;
    font-weight: normal;
  }
`

export const StyledLabel = styled.label`
  font-size: 16px;
  font-weight: 500;
  font-family: 'Poppins';
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  width: 100%;
`
