import { ICard } from "./types";



export default function Card({ title, subTitle, icon }: ICard) {
    return(
        <div className='flex flex-col font-main p-4 justify-between flex-[1_0_0] rounded-lg bg-white shadow-[5px_5px_2.5px_0_rgba(159,176,210,0.16)]'>
        <div className='flex justify-between'>
            <p className='text-3xl font-bold text-[#404040]'>
                {title}
            </p>
            <img src={icon}  alt=''/>
        </div>
        <div>
            <p className='text-[#404040] text-sm font-normal'>
                {subTitle}
            </p>
        </div>
    </div>
    )
}