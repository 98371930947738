import ky from "ky";
import { Room } from "./types";
const { REACT_APP_API_URL } = process.env;

const API_URL = REACT_APP_API_URL;

class RoomApi {
  constructor() {}

  private async getHeaders(contentType?: string) {
    const headers: Record<string, string> = {
      Accept: "application/json",
    };

    const token = localStorage.getItem("token");
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    if (contentType) {
      headers["Content-Type"] = contentType;
    }

    return headers;
  }

  public async listUserRoom(): Promise<Room> {
    const headers = await this.getHeaders("application/json");
    const selectedNetwork = localStorage.getItem("selectedNetwork");

    const response = await ky.get(
      `${API_URL}room/file/${selectedNetwork}`,
      { headers }
    );

    const room: Room = await response.json();
    return room;
  }
}

export default new RoomApi();
