import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface CustomToastProps {
    message: string;
    type?: 'success' | 'error' | 'info' | 'warning';
}

const CustomToast: React.FC<CustomToastProps> = ({ message, type = 'success' }) => {
    const notify = () => {
        toast[type](message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
            className: `toast-custom-${type}`
        });
    };

    return (
        <button onClick={notify}>
            Show Toast
        </button>
    );
};

export default CustomToast;
