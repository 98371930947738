/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from "zod";
import { SetStateAction, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import Header from "../../../components/Header";
import Button from "../../../components/Button";
import BreadcrumbsPage from "../../../components/Breadcrumbs";
import RadioInput from "../../../components/RadioInput";
import Input from "../../../components/Input";
import TextArea from "../../../components/TextAreaInput";
import ImageUpload from "../../../components/ImageUpload";
import BenefitsAPI from "../../../api/benefits/benefits";
import S3Api from "../../../api/s3/s3"

export default function NewBenefit() {
  const navigate = useNavigate();
  const location = useLocation();
  const isEdit = location.state?.isEdit;
  const dataEdit = location.state?.data;

  const newChannelCreateSchema = z.object({
    name: z.string().min(1, "Nome é obrigatório"),
    totalPoints: z.any(),
    units: z.any(),
    description: z.string().min(1, "Descrição é obrigatório"),
    file: z.any(),
    option: z.any(),
    category: z.any(),
  });

  type NewChannelsSchema = z.infer<typeof newChannelCreateSchema>;
  const [selectedValueInput, setSelectedValueInput] =
    useState<string>("Novo benefício");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [presignedUrl, setPresignedUrl] = useState<string | null>(null); 

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<NewChannelsSchema>({
    resolver: zodResolver(newChannelCreateSchema),
    defaultValues: {
      name: "",
      totalPoints: "",
      description: "",
      units: "",
      file: undefined,
      option: "Novo benefício",
    },
  });

  async function handleSubmitForm(data: NewChannelsSchema) {
    if(!isEdit && data.file){
      const presignedUrl = await S3Api.UpdatePresignedUrl(data.file.name, data.file);
      await BenefitsAPI.postNewBenefit(parseInt(data.totalPoints), parseInt(data.units), data.description, presignedUrl, data.name);
      navigate("/programa-de-fidelidade")
    }else{
      const active = data.category === "ativo";

      let presignedUrl = "";

      if(data.file){
        presignedUrl = await S3Api.UpdatePresignedUrl(data.file.name, data.file);
      }

      await BenefitsAPI.updateBenefits(dataEdit.id, active, parseInt(data.totalPoints), parseInt(data.units), data.description, presignedUrl, data.name);
      navigate("/programa-de-fidelidade")
    }
  }

  const handleImageSelect = (file: File) => {
    setValue("file", file);
    setSelectedFile(file); 
  };

  useEffect(() => {
    if (isEdit && dataEdit) {
      setValue("name", dataEdit.name);
      setValue("totalPoints", dataEdit.totalPoints);
      setValue("units", dataEdit.numberOfUnits);
      setValue("file", dataEdit.file);
      setValue("name", dataEdit.name);
      setValue("description", dataEdit.description);
      setPresignedUrl(dataEdit.file)
    }
  }, [isEdit, dataEdit, setValue]);


  return (
    <div className="flex flex-col justify-between h-screen pl-10 pt-12">
      <div>
        <div className="flex flex-col w-full gap-2">
          <div>
            <BreadcrumbsPage />
          </div>
          <div className="w-1/2">
            <Header
              title={isEdit ? "Editar benefício" : "Cadastrar benefício"}
              arrowIcon={true}
            />
          </div>
        </div>

        <div className="flex flex-col gap-6 w-full shadow-custom rounded-lg p-6">
          <form
            id="my-form"
            className="flex flex-col gap-6"
            onSubmit={handleSubmit(handleSubmitForm)}
          >
            {
              isEdit ? (
                <>
                <div className="">
                <label className="block text-base text-[#404040] font-medium">Status</label>
                <select
                  {...register("category", 
                  { required: "Categoria é obrigatória" })}
                  className="mt-2 roundend p-2 border border-[#E3E7FF] w-full h-10"
                >
                  <option value="">Selecione um status</option>
                  <option value="ativo">Ativo</option>
                  <option value="inativo">Inativo</option>
                </select>
                {errors.category && (
                  <p
                    style={{
                      color: "#c93a2f",
                      marginTop: "-10px",
                      fontSize: "0.75rem",
                    }}
                  >
                    {/* {errors.category.message} */}
                  </p>
                )}
              </div>
                </>
              ) : (
                <></>
              )
            }

            {/* <div className="flex gap-4 sm:flex-col">
              <RadioInput
                label="Novo benefício"
                value="Novo benefício"
                onChange={handleChange}
                register={register("option")}
                // errors={errors.option}
                selectedValueInput={selectedValueInput}
              />
              <RadioInput
                label="Copiar dados de outro benefício"
                value="Copiar dados de outro benefício"
                onChange={handleChange}
                register={register("option")}
                // errors={errors.option}
                selectedValueInput={selectedValueInput}
              />
            </div> */}

            <div className="flex gap-6 font-main sm:flex-col ">
              <div className="w-2/4 sm:w-[100%]">
                <Input
                  label="Nome"
                  placeholder="Digite o nome do benefício"
                  type="text"
                  register={register('name')}
                  errors={errors.name}
                />
              </div>

              <div className="flex gap-6 w-2/4 sm:flex-col w-[100%]">
                <Input
                  label="Total de pontos"
                  placeholder="Digite o total de pontos"
                  type="number"
                  register={register('totalPoints')}
                />
                <Input
                  label="Qtd. de unidades"
                  placeholder="Digite a qtd. de unidades"
                  type="number"
                  register={register('units')}
                />
              </div>
            </div>

            <div className="flex flex-col gap-4 rounded-lg">
              <TextArea 
                label="Descrição"
                register={register("description")}
                errors={errors.description}
              />
            </div>
            <div className="flex flex-col gap-4 rounded-lg">
              <ImageUpload
                title="Imagem"
                onImageSelect={handleImageSelect} 
                initialImage={presignedUrl ?? undefined}
              />
              {errors.file && (
                <p style={{ color: "#c93a2f", fontSize: "0.75rem" }}>
                  {/* {errors.file.message} */}
                </p>
              )}
            </div>
          </form>
        </div>
      </div>

      <div className="flex justify-between mt-auto shadow-custom p-10 sm:flex-col gap-6">
        <div>
          <Button
            title="Voltar"
            onClick={() => navigate(-1)}
            backGroundColor={"#FCFCFA"}
            color={"#F28E00"}
            borderRadius={8}
          />
        </div>
        <div className="flex gap-4 sm:flex-col">
          {!isEdit ? (
            <Button
              title={isEdit ? "Salvar como rascunho" : "Salvar como rascunho"}
              backGroundColor={"#4B5380"}
              color={"#FAFAFC"}
              borderRadius={8}
              type="submit"
              form="my-form"
            />
          ) : null}
          <Button
            title={isEdit ? "Salvar benefício" : "Cadastrar benefício"}
            backGroundColor={"#F28E00"}
            color={"#FAFAFC"}
            borderRadius={8}
            type="submit"
            form="my-form"
          />
        </div>
      </div>
    </div>
  );
}
