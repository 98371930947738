import styled from 'styled-components';

export const FileUpload = styled.div`
    border-radius: 4x;
    padding: 5px 10px 5px 10px;
    margin-bottom: 10px;
    background-color: #FCFCFA;
    margin-top: 10px;
    height: 40px;
    width: 100%;
    border: 1px solid;
    border-color: #E3E7FF;

    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
        line-height: 14px;
        display: flex;
        align-items: center;

        svg {
            display: inline-block;
            margin-right: 10px;
            font-size: 24px;
        }
    }

    & > svg {
        float: right;
        margin-top: 3px;
        font-size: 22px;
        cursor: pointer;

        :hover {
        }
    }
`;


export const FileUploadError = styled.div`
    padding: 5px 10px 5px 10px;
    margin-bottom: 10px;
    background-color: #FCFCFA;
    margin-top: 10px;
    height: 40px;
    width: 100%;
    border: 1px solid;
    border-color: #c93a2f;
    border-radius: 4px;

    display: flex;
    align-items: center;
    justify-content: space-between;


    span {
        line-height: 14px;
        display: flex;
        align-items: center;

        svg {
            display: inline-block;
            margin-right: 10px;
            font-size: 24px;
        }
    }

    & > svg {
        float: right;
        margin-top: 3px;
        font-size: 22px;
        cursor: pointer;

        :hover {
        }
    }
`;

export const MiniLabel = styled.label`
    color: #a9a9a9;
    padding-bottom: 5px;
    font-weight: 600;
    font-size: 10px;
    display: inline-block;
    margin-left: 5px;
`;

export const PreviaContainer = styled.fieldset`
    border-radius: 4px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #E3E7FF;
    height: 70px;
    background-color: #FCFCFA;
    align-items: center;
    color: #F28E00;
    justify-content: center;
    cursor: pointer;
    width: 190px;
    height: 40px;

    :hover {
        background-color: #eaeaea8f;
        border: 1px solid #e0e0e0;
    }
`;

export const PreviaTitle = styled.legend`
    font-weight: 600;
    margin-right: 20px;
    margin-left: 20px;
`;

export const PreviaDescription = styled.span`
    color: red;
    margin-left: 5px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;

`;

export const ImgSearch = styled.span`
    margin-left: 5px;
    text-decoration: underline;
    font-weight: 500;
`;

export const ImgTypes = styled.label`
    color: #707070;
    font-weight: 700;
    font-size: 12px;
    cursor: pointer;
`;

export const Export = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    cursor: pointer;
    svg {
        width: 26px;
        height: 26px;
        margin-right: 15px;
    }
`;
