import ky from "ky";
import { StatementResponse, StatementSchema } from "./statementModels";
const { REACT_APP_API_URL } = process.env;

const API_URL = REACT_APP_API_URL;

class StatmenteAPI {
  constructor() {}
  private async getHeaders(contentType?: string) {
    const headers: Record<string, string> = {
      Accept: "application/json",
    };

    const token = localStorage.getItem("token");
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    if (contentType) {
      headers["Content-Type"] = contentType;
    }

    return headers;
  }

  
  public async createNewStatement(
    schedule: string,
    title: string,
    fileName: any,
    text: string,
    type: number,
    file: File 
  ): Promise<unknown> {  
    const requestData = {
      schedule: schedule,
      title: title,
      text: text,
      fileName: fileName,
      type: type,
    };
    
    try {
      const headers = await this.getHeaders();
      const response = await ky.post(`${API_URL}notification`, {
        body: JSON.stringify(requestData),
        headers: {
          ...headers,
          "Content-Type": "application/json",
        },
      });
  
      return await response;
    } catch (error) {
      console.error("Erro ao cadastrar novo canal", error);
      throw error;
    }
  }
  
  private async uploadFile(file: File, presignedUrl: string): Promise<void> {
    try {
      const response = await ky.put(presignedUrl, {
        body: file, 
      });
  
      if (response.ok) {
        console.log("File uploaded successfully!");
      } else {
        console.error("Failed to upload the file:", response.statusText);
      }
    } catch (error) {
      console.error("Error during file upload:", error);
      throw error;
    }
  }
  
  
  

  public async listStatement(
    statement: StatementSchema,
    page: number,
    selectActive: number
  ): Promise<StatementResponse[] | undefined> {
    const headers = await this.getHeaders("application/json");

    let active: boolean | undefined;
    const status = statement.status?.map((x) => x.value);

    if (status && status.length > 0 && !status.includes("todos")) {
      active = status.includes("ativos")
        ? true
        : status.includes("inativos")
        ? false
        : undefined;
    }

    const storedNetworks = localStorage.getItem("networks");
    const parsedNetworks = storedNetworks ? JSON.parse(storedNetworks) : null;

    if (!parsedNetworks || parsedNetworks.length === 0) {
      console.error("Nenhuma rede encontrada no localStorage");
      return undefined;
    }

    const payload: any = {
      Active: active,
      NetworkUnitId: selectActive,
      Title: statement.title,
      PageSize: page,
      OffSet: 1,
    };

    if (statement.startDate) {
      payload.StartDate = statement.startDate;
    }
    if (statement.endDate) {
      payload.EndDate = statement.endDate;
    }

    try {
      const response = await ky
        .post(
          `${API_URL}notification/filter-notification`,
          {
            json: payload,
            headers,
          }
        )
        .json<StatementResponse[]>();

      return response;
    } catch (error) {
      console.error("Erro ao listar comunicados", error);
      return undefined;
    }
  }

  public async deleteStatement(id: string): Promise<unknown> {
    const headers = await this.getHeaders("application/json");
    try {
      const response = await ky.delete(
        `${API_URL}notification/${id}`,
        {
          headers,
        }
      );
      return response;
    } catch (error) {
      console.error("Erro ao deletar comunicado", error);
    }
  }

  public async editStatement(
    id: string,
    Schedule: string,
    Name: string,
    File: File | null,
    UserIds: string[]
  ): Promise<unknown> {
    const formData = new FormData();
    formData.append("Schedule", Schedule);
    formData.append("Name", Name);
    formData.append("Schedule", Schedule);
    formData.append("Schedule", Schedule);
    UserIds.forEach((id) => formData.append("UserIds[]", id));

    if (File) {
      formData.append("File", File);
    }

    try {
      const headers = await this.getHeaders();
      headers["Content-Type"] = "multipart/form-data";
      const data = await ky.put(
        `${API_URL}notification/${id}`,
        { body: formData, headers }
      );
      return data;
    } catch (error) {
      console.error("Erro ao editar comunicado", error);
      throw error;
    }
  }
}

export default new StatmenteAPI();
