/* eslint-disable jsx-a11y/alt-text */
import { FC } from "react";
import * as Styled from "./styles";
import CloseIcon from "../../assets/icons/close-icon.svg";
import Button from "../Button";

interface IProps {
  itemName: string;
  confirm: () => void;
  cancel: () => void;
}

const DeleteModal: FC<IProps> = ({ itemName, confirm, cancel }: IProps) => {
  return (
    <Styled.Container data-testid="DeleteModal">
      <Styled.Modal>
        <Styled.CoontainerHeader>
          <Styled.Close onClick={cancel}>
            <img src={CloseIcon} />
          </Styled.Close>
          <Styled.ContainerTitle>
            <p>Atenção</p>
          </Styled.ContainerTitle>
        </Styled.CoontainerHeader>

        <Styled.ContainerDescription>
          <p>
            Deseja prosseguir com a exclusão do canal <span>“{itemName}”</span>?
          </p>
        </Styled.ContainerDescription>

        <Styled.OptionsContainer>
          <Button
            title="Cancelar"
            backGroundColor="white"
            color="#8A97B1"
            borderRadius={8}
            onClick={cancel}
          />

          <Button
            title="Excluir"
            backGroundColor="#D42A2A"
            color="#fff"
            borderRadius={8}
            onClick={confirm}
          />
        </Styled.OptionsContainer>
      </Styled.Modal>
    </Styled.Container>
  );
};

export default DeleteModal;
