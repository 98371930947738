

export function getColumns(
  
) {
    return [
        {
            name: 'Nome do proprietário',
            selector: (row: { name: string }) => (
                <span>{row.name}</span>
            ),
        },
        {
            name: 'Data de resgate',
            selector: (row: { data_do_resgaste: string }) => (
                <span>{row.data_do_resgaste}</span> 
            ),
        },
      
       
    ];
}
