import { ButtonProps } from "./types";

export default function Button({ title, backGroundColor, color, borderRadius, borderColor, onClick, form, type }: ButtonProps) {
    const buttonStyle = {
        backgroundColor: backGroundColor,
        color: color,
        borderRadius: `${borderRadius}px`,
        borderColor: `${borderColor}`,
        padding: '10px 20px',
        border: '1px solid',
        cursor: 'pointer',
        outline: 'none'
    };

    return (
        <button style={buttonStyle} onClick={onClick} form={form} type={type}>
            {title}
        </button>
    );
}
