/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { Grid2 } from "@mui/material";
import Header from "../../components/Header";
import SelectEnterprise from "../../components/SelectEnterprise";
import Input from "../../components/Input";
import { Controller, useForm } from "react-hook-form";
import MultiSelect from "../../components/MultiSelect";
import PageSize from "../../components/Table/PageSize";
import Table from "../../components/Table";
import Pagination from "../../components/Table/Pagination";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useNavigate } from "react-router-dom";
import { SetStateAction, useEffect, useState } from "react";
import { getColumns } from "./columns";
import Button from "../../components/Button";
import DeleteModal from "../../components/DeleteModal";
import { IFiles } from "./types";
import file from "../../api/file/file";

export default function FilesPage() {
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [numberOfPages, setNumberOfPages] = useState<number>(0);
  const [deletingFile, setDeletingFile] = useState<IFiles | null>(null);
  const [selectedValues, setSelectedValues] = useState([]);
  const [useDataFiles, setUseDataFiles] = useState<any>([]);
  const [selectActive, setSelectActive] = useState();

  const dataEdit = [{ name: "teste", category: 1 }];
  const data = [
    { value: "ativos", label: "Ativos" },
    { value: "inativos", label: "Inativos" },
  ];

  const handleSelectedHotelChange = (selectedHotelId: number | null) => {
    setSelectActive(selectedHotelId)
  };

  const handleMultiSelectChange = (values: SetStateAction<never[]>) => {
    setSelectedValues(values);
  };

  const newChannelCreateSchema = z.object({
    title: z.any().optional(),
    status: z.any().optional(),
  });

  type NewChannelsSchema = z.infer<typeof newChannelCreateSchema>;

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<NewChannelsSchema>({
    resolver: zodResolver(newChannelCreateSchema),
  });

  async function handleFilter(data: any) {
    const title = data.title?.toLowerCase() || "";
    const status = data.status?.[0]?.value;

    if ((!title && typeof status === "undefined") || status === "todos") {
      listFiles(pageSize, currentPage);
      return;
    }

    let filteredData = useDataFiles;
    if (status) {
      filteredData = filteredData.filter((canal: any) =>
        status === "inativos" ? !canal.Active : canal.Active
      );
    }

    if (title) {
      filteredData = filteredData.filter((each: any) =>
        each.Name.toLowerCase().includes(title)
      );
    }

    setUseDataFiles(filteredData);
  }

  function clearFilters() {
    reset();
  }

  function resetAll() {
    setPageSize(10);
    setCurrentPage(1);
    setSelectedValues([]);
    reset();
    listFiles(10, 1); 
  }

  const editFile = () => {
    navigate("/arquivos/editar-arquivo", {
      state: { isEdit: true, data: dataEdit },
    });
  };

  async function downloadDocumentHandler(userParam: any) {
    if (!userParam.File) {
      console.error("URL do arquivo não está definida.");
      return;
    }
    try {
      const response = await fetch(userParam.File);
      if (!response.ok) {
        throw new Error("Não foi possível baixar o arquivo");
      }
      const blob = await response.blob();
      const downloadUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = userParam.file_url.split("/").pop() || "download";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error("Erro ao baixar o arquivo:", error);
    }
  }

  useEffect(() => {
    console.log("selectActive",selectActive)
  },[selectActive])

  async function listFiles(pageSize: number, currentPage: number) {
    try {
      // Verificar se selectActive possui um valor válido
      if (selectActive) {
        const response = (await file.listFile(pageSize, currentPage, selectActive)) as IFiles[];
        setUseDataFiles(response);
        return response;
      } else {
        console.log("selectActive não tem valor definido.");
        return [];  // ou outro valor de retorno que você prefira quando selectActive não tiver valor
      }
    } catch (error) {
      console.error("Erro ao listar os arquivos", error);
    }
  }
  

  async function deleteItem(deletingItem: any) {
    const response = await file.deleteFile(deletingItem.Id);
    setDeletingFile(null);
    return response;
  }

  useEffect(() => {
    listFiles(pageSize, currentPage);
  }, [currentPage, pageSize]);

  useEffect(() => {
    listFiles(pageSize, currentPage);

  },[selectActive])

  return (
    <Grid2 container spacing={1} mt={5} ml={5} mr={1}>
      <div className="flex w-full">
        <div className="w-1/2">
          <Header
            title="Documentos"
            subTitle="Visualize e gerencie os documentos disponibilizados aos usuários."
            arrowIcon={false}
          />
        </div>
        <div className="flex flex-col items-end gap-5 w-1/2">
          <div>
          <SelectEnterprise onSelectedHotelChange={handleSelectedHotelChange} />
          </div>

          <div>
            <Button
              title="+ Novo grupo de documentos"
              color="#fff"
              backGroundColor="#F28E00"
              borderRadius={8}
              onClick={() => navigate("/arquivos/cadastrar-arquivo")}
            />
          </div>
        </div>
      </div>

      <div className="w-screen shadow-custom rounded-lg p-6">
        <form
          className="flex flex-col gap-6"
          onSubmit={handleSubmit(handleFilter)}
        >
          <div className="flex gap-6">
            <div className="w-2/4">
              <Input
                label="Nome"
                placeholder="Digite o nome do grupo de documentos"
                type="text"
                register={register("title")}
              />
            </div>
            <div className="w-2/4">
            <MultiSelect
                label="Status"
                data={data}
                onChange={handleMultiSelectChange}
                style={{ width: "100%" }}
                file={false} register={undefined} errors={undefined} message={undefined} />
          </div>
          </div>
          <div className="flex justify-end gap-4">
            <Button
              title="Limpar"
              color="#F28E00"
              backGroundColor="#FCFCFA"
              borderColor="#E3E7FF"
              borderRadius={8}
              onClick={resetAll}
              type="reset"
            />
            <Button
              title="Buscar"
              color="#fff"
              backGroundColor="#F28E00"
              borderRadius={8}
              onClick={handleSubmit(handleFilter)}
            />
          </div>
        </form>
      </div>

      <div className="flex flex-col gap-6 w-screen shadow-custom rounded-lg p-6">
        <div className="flex justify-between">
          <div className="text-[#6D7882] font-main">
            <p>
              <span className="font-bold font-main">{useDataFiles?.length}</span> itens cadastrados
            </p>
          </div>
          <div className="flex gap-4 items-center">
            <PageSize
              pageSize={pageSize}
              setPageSize={(size) => {
                setPageSize(size);
                setCurrentPage(1);
              }}
            />
            <p className="text-[#6D7882]">Itens por página</p>
          </div>
        </div>
              
        <Table
          data={useDataFiles}
          columns={getColumns(
            editFile,
            setDeletingFile,
            downloadDocumentHandler,
            false
          )}
          hidePagination
        />
      </div>
      {deletingFile && (
        <DeleteModal
          itemName={deletingFile.name}
          confirm={() => deleteItem(deletingFile)}
          cancel={() => setDeletingFile(null)}
        />
      )}
    </Grid2>
  );
}
