/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { z } from "zod";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import Header from "../../../components/Header";
import Input from "../../../components/Input";
import ImageUpload from "../../../components/ImageUpload";
import TextEditor from "../../../components/TextEditor";
import Button from "../../../components/Button";
import StatmenteAPI from "../../../api/statement/statement";
import MultiSelect from "../../../components/MultiSelect";
import FileApi from "../../../api/file/file";
import S3Api from "../../../api/s3/s3"
import CustomToast from "../../../components/Toast";

export default function NewStatement() {
  const navigate = useNavigate();
  const location = useLocation();
  const isEdit = location.state?.isEdit;
  const dataEdit = location.state?.data;
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [editorContent, setEditorContent] = useState<string>("");
  const [selectedValues, setSelectedValues] = useState([]);
  const [users, setUsers] = useState<ResponseUsers[]>([]);

  const newChannelCreateSchema = z.object({
    title: z.string().min(1, "Título é obrigatório"),
    dataPublishing: z.string().min(1, "Data é obrigatório"),
    responsibleUsers: z.any(),
    // responsibleUsers: z.array(
    //   z.object({
    //     value: z.string(),
    //     label: z.string(),
    //   })
    // ).nonempty("Campo de responsáveis é obrigatório"), 
    responsibleTypeUsers: z.array(
      z.object({
        value: z.string(),
        label: z.string(),
      })
    ).nonempty("Campo de responsáveis é obrigatório"), 
  });

  type NewChannelsSchema = z.infer<typeof newChannelCreateSchema>;

  const handleToastSuccess = () => {
    return (
      <CustomToast message="Canal cadastrado com sucesso" type="success" />
    );
  };

  const handleToastSuccessEdit = () => {
    return (
      <CustomToast message="Canal atualizado com sucesso" type="success" />
    );
  };

  const listUsersResponsible = [
    { value: "todos", label: "Todos os Proprietários" },
    { value: "dentroPool", label: "Proprietários dentro do pool" },
    { value: "foraPool", label: "Proprietários fora do pool" },
  ];
  
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<NewChannelsSchema>({
    resolver: zodResolver(newChannelCreateSchema),
  });
  const handleMultiSelectChange = (values) => {
    const uniqueValues = Array.from(new Set(values));
    setSelectedValues(uniqueValues);
  };

  async function handleSubmitForm(data: NewChannelsSchema) {
    let type = '';
  
    if (data.responsibleTypeUsers[0].value === 'todos') {
      type = 1;
    }
    if (data.responsibleTypeUsers[0].value === 'dentroPool') {
      type = 2;
    }
    if (data.responsibleTypeUsers[0].value === 'foraPool') {
      type = 3;
    }
  
    if (isEdit) {
      const id = "1";
      const response = await StatmenteAPI.editStatement(
        id,
        data.dataPublishing,
        data.title,
        selectedImage,
        editorContent,  
        type
      );
      navigate(-1);
      return response;
    } else {      
      const presignedUrl = await S3Api.UpdatePresignedUrl(selectedImage.name, selectedImage);
      const response = await StatmenteAPI.createNewStatement(
        data.dataPublishing,
        data.title,
        presignedUrl,
        editorContent,  
        type
      );
      handleToastSuccess();
      navigate(-1);
      return response;
    }
  }
  
  


  useEffect(() => {
    if (isEdit && dataEdit) {
      setValue("title", dataEdit[0].title);
  
      if (dataEdit.photo) {
        setSelectedImage({ name: dataEdit[0].photo }); 
      }
  
      setEditorContent(dataEdit[0].text);
  
      const formattedDate = new Date(dataEdit[0].scheduling).toISOString().split("T")[0];
      setValue("dataPublishing", formattedDate);
    }
  }, [isEdit, dataEdit, setValue]);

  
  async function listUser() {
    try {
      const response = await FileApi.listUsers();
      if (response) {
        const formattedUsers = response.map((user) => ({
          value: user.Id.toString(),
          label: user.Name,
        }));
        setUsers(formattedUsers);
      }
    } catch (error) {
      console.error("Erro ao listar usuários", error);
    }
  }

  useEffect(() => {
    listUser();
  }, []);
  return (
    <div className="flex flex-col justify-between h-screen pl-10">
      <div>
        <div className="flex w-full">
          <div className="w-screen">
            <Header
              title={isEdit ? "Editar comunicado" : "Cadastrar Comunicado"}
              arrowIcon={true}
            />
          </div>
        </div>
        <form id="my-form" onSubmit={handleSubmit(handleSubmitForm)}>
          <div className="flex flex-col gap-6 w-full shadow-custom rounded-lg p-6">
            <div className="flex flex-col gap-4 rounded-lg">
              <Controller
                name="responsibleTypeUsers"
                control={control}
                render={({ field }) => (
                  <MultiSelect
                    label="Disponibilizar para"
                    data={listUsersResponsible}
                    onChange={(values: SetStateAction<never[]>) => {
                      field.onChange(values);
                      handleMultiSelectChange(values);
                    }}
                    style={{ width: "100%" }}
                    register={field}
                    errors={errors.responsibleTypeUsers}
                    message="Campo obrigatório"
                    file={false}
                  />
                )}
              />
            <div className="flex gap-6">

              <div className="w-1/2">
                <Input
                  label="Título"
                  placeholder="Digite o título do comunicado"
                  type="text"
                  register={register("title")}
                  errors={errors.title}
                />
              </div>
              <div className="w-1/2">
                <Input
                  label="Publicação"
                  placeholder="Informe a data de publicação"
                  type="date"
                  register={register("dataPublishing")}
                  errors={errors.dataPublishing}
                />
              </div>
              </div>

            </div>
            {/* <div className="flex flex-col gap-4 rounded-lg">
              <Controller
                  name="responsibleUsers"
                  control={control}
                  defaultValue={[]} // Inicialize com um array vazio
                  render={({ field }) => (
                    <MultiSelect
                      label="Usuários"
                      data={users} // Dados formatados
                      onChange={field.onChange} // Atualiza o estado do formulário diretamente
                      register={field} // Integra o `react-hook-form`
                      style={{ width: "100%" }}
                      errors={errors.responsibleUsers}
                      message="Campo obrigatório"
                      file={false} // Indica que não está trabalhando com arquivos
                    />
                  )}
                />
              {selectedValues.length > 0 ? (
                <div>
                  <p>{selectedValues.length} usuário(s) selecionado(s)</p>
                </div>
              ) : (
                <div className="bg-[#F4F5F7] p-2 px-4 rounded-md text-[#404040] font-main">
                  <p>Nenhum usuário selecionado</p>
                </div>
              )}
            </div> */}
            <div>
            <ImageUpload
              title="Foto da capa"
              onImageSelect={setSelectedImage}
              initialImage={isEdit && dataEdit?.photo ? dataEdit.photo : null}
            />
            </div>
            <div>
              <TextEditor
                label=""
                value={editorContent}
                onChange={setEditorContent}
              />
            </div>
          </div>
        </form>
      </div>
      <div className="p-10">
        <div className="flex justify-between ">
          <Button
            title="Voltar"
            onClick={() => navigate(-1)}
            backGroundColor="#fff"
            color="#F28E00"
            borderRadius={8}
          />
          <Button
            title={isEdit ? "Salvar comunicado" : "Cadastrar comunicado"}
            backGroundColor="#F28E00"
            color="#FAFAFC"
            borderRadius={8}
            form="my-form"
            type="submit"
          />
        </div>
      </div>
    </div>
  );
}
