import styled from 'styled-components';

export const Menu = styled.button`
    border: none;
    background: none;
    padding: 0;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    svg {
        width: 20px;
    }
`;
