/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from "react";
import Header from "../../../components/Header";
import BreadcrumbsPage from '../../../components/Breadcrumbs';
import IconBallon from "../../../assets/icons/circle-balloon.svg";
import IconCircleCheck from "../../../assets/icons/circle-check-green.svg";
import IconVoucher from "../../../assets/icons/circle-yellow-vourcher.svg";
import Table from "../../../components/Table";
import PageSize from "../../../components/Table/PageSize";
import Pagination from "../../../components/Table/Pagination"
import { getColumns } from "./columns"
import Card from '../../../components/Card';

export default function ViewReport() {

    const [pageSize, setPageSize] = useState<number>(10);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [numberOfPages, setNumberOfPages] = useState<number>(0);

    const useData = [{name: 'Susana Fonseca Araújo', data_do_resgaste: '11/09/2024'},
        {name: 'Antônio Nunes Tavares', data_do_resgaste: '09/09/2024' },
        {name: 'Bárbara Moreira Barbosa', data_do_resgaste: '04/09/2024'},
    ]

    return (
        <div className="flex flex-col justify-between h-screen pl-10 pt-12">
            <div className='flex flex-col gap-6 pr-4'>
                <div className="flex flex-col w-full gap-2">
                    <div>
                        <BreadcrumbsPage />
                    </div>
                    <div>
                        <Header
                            title="Visualizar relatórios - Jantar Fajã"
                            arrowIcon={true}
                        />
                    </div>
                </div>

                <div className='flex gap-6 sm:flex-col'>
                    <Card 
                        title='4.042'
                        subTitle='Solicitações executadas'
                        icon={IconBallon}
                    />

                    <Card 
                        title='3.912'
                        subTitle='Solicitações aprovadas'
                        icon={IconCircleCheck}
                    />

                    <Card 
                        title='66'
                        subTitle='Vouchers disponíveis'
                        icon={IconVoucher}
                    />
                   
                </div>

                <div className="flex justify-between " >
                    <div className="text-[#6D7882]">
                        <p><span className="font-bold">{useData.length}</span> proprietários usaram este benefício</p>
                    </div>
                    <div className="flex gap-4 items-center">
                        <PageSize
                                pageSize={pageSize}
                                setPageSize={(size) => {
                                setPageSize(size);
                                setCurrentPage(1);
                            }}
                        />
                        <p className="text-[#6D7882]">Itens por página</p>
                    </div>
                </div>

                <div>
                    <Table 
                        data={useData}
                        columns={getColumns()}
                        hidePagination
                    />
                    <Pagination
                            pageCount={numberOfPages}
                            currentPage={currentPage - 1}
                            setPage={(page) => {
                                setCurrentPage(page + 1); 
                            }}
                            setPagePrevious={page => setCurrentPage(page + 1)}
                    />
                </div>
            </div>
        </div>
    );
}
