import { z } from "zod";
import { SetStateAction, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import Header from "../../../components/Header";
import IconSelector from "../../../components/IconSelector";
import Input from "../../../components/Input";
import MultiSelect from "../../../components/MultiSelect";
import Button from "../../../components/Button";
import UploadContract from "../../../components/Upload";
import BreadcrumbsPage from "../../../components/Breadcrumbs";
import FileApi from "../../../api/file/file";
import RoomApi from "../../../api/room/room";
import { ResponseUsers } from "./types";
import "react-toastify/dist/ReactToastify.css";
import "../../../index.css";
import CustomToast from "../../../components/Toast";
import React from "react";
import { Room } from "../../../api/room/types";
import apiCategory from "../../../api/category/apiCategory";
import IconFile from "../../../assets/icons/icon-upload.png"

type Category = {
  Id: number;
  Name: string;
};

export default function NewFile() {
  const navigate = useNavigate();
  const location = useLocation();
  const isEdit = location.state?.isEdit;
  const dataEdit = location.state?.data;
  const [users, setUsers] = useState<ResponseUsers[]>([]);
  const [room, setRoom] = useState<Room>([]);
  const [selectedIconFile, setSelectedIconFile] = useState<File | null>(null);
  const [categories, setCategories] = useState<{ Id: number; Name: string }[]>([]);

  const handleToastSuccess = () => {
    return (
      <CustomToast message="Arquivo cadastrado com sucesso" type="success" />
    );
  };

  const handleIconSelect = (icon: React.ReactNode) => {
    if (React.isValidElement(icon) && icon.props?.src) {
      fetch(icon.props.src)
        .then((response) => response.blob())
        .then((blob) => {
          const file = new File([blob], "icon.png", { type: blob.type });
          setSelectedIconFile(file);
        })
        .catch((error) => {
          console.error("Erro ao buscar o ícone", error);
        });
    } else {
      console.error("Ícone selecionado inválido.");
    }
  };

  const [selectedValues, setSelectedValues] = useState([]);
  const handleMultiSelectChange = (values: SetStateAction<never[]>) => {
    setSelectedValues(values);
  };

  // const newChannelCreateSchema = z.object({
  //   name: z.string().min(1, "Nome é obrigatório"),
  //   responsibleUsers: z.any(),
  //   file: z.any(),
  //   // file: z
  //   //   .any()
  //   //   .refine((files) => files.length === 1, "Arquivo é obrigatório.")
  //   //   .refine(
  //   //     (files) => files?.[0]?.size <= 10000000,
  //   //     "Tamanho máximo de 10MB."
  //   //   ),
  //   category: z.string().min(1, "Categoria é obrigatória"),

  // });

  const newChannelCreateSchema = z.object({
    name: z.string().min(1, "Nome é obrigatório"),
    responsibleUsers: z.any(),
    file: z.array(z.instanceof(File)).min(1, "Pelo menos um arquivo deve ser enviado"), // Garantir array de arquivos
    category: z.string().min(1, "Categoria é obrigatória"),
    responsibleTypeUsers: z.array(
      z.object({
        value: z.string(),
        label: z.string(),
      })
    ).nonempty("Campo de responsáveis é obrigatório"), 
  });
  
  const listUsersResponsible = [
    { value: "todos", label: "Todos os Proprietários" },
    { value: "dentroPool", label: "Proprietários dentro do pool" },
    { value: "foraPool", label: "Proprietários fora do pool" },
  ];

  type NewChannelsSchema = z.infer<typeof newChannelCreateSchema>;

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<NewChannelsSchema>({
    resolver: zodResolver(newChannelCreateSchema),
    defaultValues: {
      name: "",
      responsibleUsers: "",
      file: [],
      category: "",
    },
  });

  async function listCategories() {
    try {
      const response = await apiCategory.getListCategory()
      setCategories(response as Category[]);      
      return response
    } catch(error) {
      console.error('Erro ao listar categorias', error)
    }
  }

  async function handleSubmitForm(data: NewChannelsSchema) {
    let type = '';
  
    if (data.responsibleTypeUsers[0].value === 'todos') {
      type = '1';
    }
    if (data.responsibleTypeUsers[0].value === 'dentroPool') {
      type = '2';
    }
    if (data.responsibleTypeUsers[0].value === 'foraPool') {
      type = '3';
    }
    try {
      if (isEdit) {
        const id = "1";
        const response = await FileApi.editFile(
          id,
          selectedIconFile,
          data.file,
          data.name,
          type,
          data.category
        );
        navigate(-1);
        return response;
      } else {
        const response = await FileApi.postNewFile(
          selectedIconFile,
          data.file, 
          data.name,
          type,
          data.category
        );
        handleToastSuccess();
        navigate(-1);
        return response;
      }
    } catch (error) {
      console.error("Erro ao cadastrar arquivo", error);
    }
  }
  

  async function listUser() {
    const response = await FileApi.listUsers();
    const rooms = await RoomApi.listUserRoom();
    if (rooms) setRoom(rooms);
    if (response) setUsers(response);
  }
  useEffect(() => {
    listUser();
    listCategories()
  }, []);

  useEffect(() => {
    if (isEdit && dataEdit) {
      setValue("name", dataEdit[0].name);
    }
  }, [isEdit, dataEdit, setValue]);

  return (
    <div className="flex flex-col justify-between h-screen pl-10 pt-12">
      <div>
        <div className="flex flex-col w-full gap-2">
          <div>
            <BreadcrumbsPage />
          </div>
          <div className="w-1/2">
            <Header
              title={isEdit ? "Editar grupo de documentos" : "Cadastrar grupo de documentos"}
              arrowIcon={true}
            />
          </div>
        </div>

        <div className="flex flex-col gap-6 w-full shadow-custom rounded-lg p-6">
          <form
            id="my-form"
            className="flex flex-col gap-6"
            onSubmit={handleSubmit(handleSubmitForm)}
          >
            <div className="flex flex-col gap-4 rounded-lg">
              <Controller
                name="responsibleTypeUsers"
                control={control}
                render={({ field }) => (
                  <MultiSelect
                    label="Disponibilizar para"
                    data={listUsersResponsible}
                    onChange={(values: SetStateAction<never[]>) => {
                      field.onChange(values);
                      handleMultiSelectChange(values);
                    }}
                    style={{ width: "100%" }}
                    register={field}
                    errors={errors.responsibleTypeUsers}
                    message="Campo obrigatório"
                    file={false}
                  />
                )}
              />
              </div>
            <div className="flex gap-4">
              <div>
                <IconSelector onIconSelect={handleIconSelect} />
              </div>

          
              <div className="w-2/5">
                <Input
                  label="Nome"
                  type="text"
                  placeholder="Digite o nome do grupo de documentos"
                  register={register("name")}
                  errors={errors.name}
                />
              </div>
              <div className="w-2/5">
                <label className="block text-base text-[#404040] font-medium">Categoria</label>
                {/* <select
                  {...register("category", { required: "Categoria é obrigatória" })}
                  className="mt-2 roundend p-2 border border-[#E3E7FF] w-full h-10"
                >
                  <option value="">Selecione uma categoria</option>
                  <option value="financeiro">Financeiro</option>
                </select> */}
                <select
                    {...register("category", { required: "Categoria é obrigatória" })}
                    className="mt-2 rounded p-2 border border-[#E3E7FF] w-full h-10"
                  >
                    <option value="">Selecione uma categoria</option>
                    {categories.map((category) => (
                      <option key={category.Id} value={category.Id}>
                        {category.Name}
                      </option>
                    ))}
                  </select>
                {errors.category && (
                  <p
                    style={{
                      color: "#EF4444",
                      marginTop: "10px",
                      fontSize: "14px",
                      
                    }}
                  >
                    {errors.category.message}
                  </p>
                )}
              </div>
            </div>

            <div className="font-main">
              <div className="font-medium text-[#404040]">
                <p>Arquivo</p>
              </div>
              <Controller
                name="file"
                control={control}
                render={({ field }) => (
                  <>
                    <UploadContract
                      name="file"
                      onUpload={(files) => {
                        field.onChange(files);
                      }}
                      hasFile={!!field.value}
                      error={errors.file}
                    />
                    {errors.file && (
                      <p
                        style={{
                          color: "#EF4444",
                          marginTop: "10px",
                          fontSize: "14px",
                        }}
                      >
                        {String(errors.file.message)}
                      </p>
                    )}
                  </>
                )}
              />
              <p style={{ color: "#6D7882", fontSize: "14px" }}>
                Tamanho máximo 10Mb
              </p>
            </div>
          </form>
          



        </div>
      </div>

      <div className="flex justify-between mt-auto shadow-custom p-10">
        <Button
          title="Voltar"
          onClick={() => navigate(-1)}
          backGroundColor={"#FCFCFA"}
          color={"#F28E00"}
          borderRadius={8}
        />
        <Button
          title={isEdit ? "Salvar arquivo" : "Cadastrar arquivo"}
          backGroundColor={"#F28E00"}
          color={"#FAFAFC"}
          borderRadius={8}
          type="submit"
          form="my-form"
        />
      </div>
    </div>
  );
}
