/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import Header from "../../../components/Header";
import Input from "../../../components/Input";
import MultiSelect from "../../../components/MultiSelect";
import SelectEnterprise from "../../../components/SelectEnterprise";
import { Grid2 } from "@mui/material";
import { useEffect, useState } from "react";
import PageSize from "../../../components/Table/PageSize";
import Table from "../../../components/Table";
import Pagination from "../../../components/Table/Pagination";
import { getColumns } from "./columns";
import DeleteModal from "../../../components/DeleteModal";
import { z } from "zod";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import RadioInput from "../../../components/RadioInput";
import IconCirlceGreen from "../../../assets/icons/circle-green-ligth.svg";
import IconCircleWhite from "../../../assets/icons/circle-white.svg";
import pointsApi from "../../../api/points/pointsApi";

enum inputType {
  ENTRY = "ENTRY",
  OUTPUT = "OUTPUT",
}

type Unit = {
  Number: string;
  RoomId: number;
  UserId: number;
};

export default function Points() {
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [numberOfPages, setNumberOfPages] = useState<number>(0);
  const [deletingContract, setDeletingContract] = useState<null>(null);
  const [selectedValues, setSelectedValues] = useState([]);
  const [itemType, setItemType] = useState<inputType>();
  const [listPoints, setListPoints] = useState([]);
  const [bigNumber, setBigNumber] = useState([]);
  const [units, setUnits] = useState<Unit[]>([]);
  const [useType, setUseType] = useState<[]>([]);
  const [selectActive, setSelectActive] = useState();


  const handleSelectedHotelChange = (selectedHotelId: number | null) => {
    setSelectActive(selectedHotelId)
  };

  const data = [
    { value: 'todos', label: 'Todos' },
    { value: 'entrada', label: 'Entrada' },
    { value: 'saida', label: 'Saída' }
  ];
  
  const dataEdit = [{ name: "teste", category: 1 }];

  const handleMultiSelectChange = (values) => {
    setSelectedValues(values);
  };

  const viewReport = (userParam: unknown) => {
    navigate("/programa-de-fidelidade/visualizar-relatorios", {
      state: { isEdit: true, data: dataEdit },
    });
  };

  const editChannel = () => {
    navigate("/programa-de-fidelidade/editar-beneficio", {
      state: { isEdit: true, data: dataEdit },
    });
  };

  function deleteItem(deletingItem) {
    setDeletingContract(null);
  }

  const newChannelCreateSchema = z.object({
    type: z.any(),
    unity: z.number().min(1, "Unidade é obrigatória"),
  });

  type NewChannelsSchema = z.infer<typeof newChannelCreateSchema>;

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<NewChannelsSchema>({
    resolver: zodResolver(newChannelCreateSchema),
  });

  function handleSubmitForm(data: NewChannelsSchema) {

  }

  const handleChange = (value: string) => {
    setSelectedValueInput(value);
  };

  async function getUnit() {
    try {
      const response = await pointsApi.getUnity()
      setUnits(response);
      return response;
    } catch(error) {
      console.error('Erro ao listar unidades', error)
    }
  }

  async function getListPoints() {
    try {
      const response = await pointsApi.getPoints()
      setListPoints(response);
      return response;
    } catch(error) {
      console.error('Erro ao listar unidades', error)
    }
  }
  async function getListBigNumber() {
    try {
      const response = await pointsApi.getBigNumberPoints()
      setBigNumber(response[0].count)
      return response;
    } catch(error) {
      console.error('Erro ao big number ', error)
    }
  }


  useEffect(() => {
    getUnit()
    getListPoints()
    getListBigNumber()
  },[])

  function mergeSelectedValues(currentValues, selectedValue) {
    const isAlreadySelected = currentValues.some(
      (item) => item.Number.trim() === selectedValue.Number.trim() // Comparação usando a propriedade `Number` sem espaços extras
    );
  
    const updatedValues = isAlreadySelected
      ? currentValues.filter(
          (item) => item.Number.trim() !== selectedValue.Number.trim() // Remove o valor se já está selecionado
        )
      : [...currentValues, selectedValue];
  
    return updatedValues;
  }

  function updateSelection(selectedItems: Unit[], newItem: Unit[]): Unit[] {
    const updatedSelection = [...selectedItems]; 
  
    newItem.forEach(item => {
      const isAlreadySelected = updatedSelection.some(
        (selectedItem) => selectedItem.RoomId === item.RoomId 
      );
  
      if (isAlreadySelected) {
        const index = updatedSelection.findIndex(
          (selectedItem) => selectedItem.RoomId === item.RoomId
        );
        updatedSelection.splice(index, 1);
      } else {
        updatedSelection.push(item);
      }
    });
  
    return updatedSelection;
  }
  

  useEffect(() => {
    console.log("units",units)
  },[units])

  return (
    <Grid2 container spacing={1} mt={5} ml={5} mr={1}>
      <div className="flex w-full sm:flex-col">
        <div className="w-1/2">
          <Header
            title="Pontos"
            subTitle="Realize a inclusão dos pontos para os proprietários"
            arrowIcon={false}
          />
        </div>
        <div className="flex flex-col items-end  gap-5 w-1/2 sm:w-[100%]">
          <div>
            <SelectEnterprise onSelectedHotelChange={handleSelectedHotelChange} />
          </div>

          <div>
            <Button
              title="+ Nova inclusão de pontos"
              color="#fff"
              backGroundColor="#F28E00"
              borderRadius={8}
              onClick={() => navigate("/programa-de-fidelidade/incluir-pontos")}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-6 w-screen shadow-custom rounded-lg p-6">
        <div className="flex gap-6 sm:flex-col">
          <div className="w-2/4 sm:w-[100%]">
          <Controller
            name="unity"
            control={control}
            render={({ field }) => (
              <MultiSelect
                label="Unidade"
                data={units} // Dados disponíveis para seleção
                onChange={(selectedValues: Unit[]) => {
                  const updatedValues = updateSelection(field.value || [], selectedValues);
                  field.onChange(updatedValues); // Atualiza o valor do formulário
                }}
                style={{ width: "100%" }}
                selectedValues={field.value || []} // Exibe os itens selecionados corretamente
                errors={errors.unity}
                message="Campo obrigatório"
                file={false}
              />
            )}
          />

          </div>
          <div className="w-2/4 sm:w-[100%]">
          <Controller
            name="type"
            control={control}
            render={({ field }) => (
              <MultiSelect
                label="Tipo de inclusão"
                data={data}  // Passando as opções para o MultiSelect
                onChange={(values) => {
                  field.onChange(values);
                  handleMultiSelectChange(values);  // Atualiza o estado no pai
                }}
                style={{ width: "100%" }}
                register={field}
                errors={errors.type}
                message="Campo obrigatório"
                file={false}
              />
            )}
          />
          </div>
 
        </div>
        <div className="flex justify-end gap-4">
          <Button
            title="Limpar"
            color="#F28E00"
            backGroundColor="#FCFCFA"
            borderColor="#E3E7FF"
            borderRadius={8}
            onClick={() => console.log("")}
          />
          <Button
            title="Buscar"
            color="#fff"
            backGroundColor="#F28E00"
            borderRadius={8}
            onClick={() => handleSubmitForm()}
          />
        </div>
      </div>

      <div className="flex flex-col gap-6 w-screen shadow-custom rounded-lg p-6">
        <div className="flex justify-between">
          <div className="text-[#6D7882]">
            <p>
              <span className="font-bold">{bigNumber}</span> itens
              cadastrados
            </p>
          </div>
          <div className="flex gap-4 items-center">
            <PageSize
              pageSize={pageSize}
              setPageSize={(size) => {
                setPageSize(size);
                setCurrentPage(1);
              }}
            />
            <p className="text-[#6D7882]">Itens por página</p>
          </div>
        </div>

        <Table
          data={listPoints}
          columns={getColumns(viewReport, editChannel, setDeletingContract)}
          hidePagination
        />
        <Pagination
          pageCount={numberOfPages}
          currentPage={currentPage - 1}
          setPage={(page) => {
            setCurrentPage(page + 1);
          }}
          setPagePrevious={(page) => setCurrentPage(page + 1)}
        />
      </div>
      {deletingContract && (
        <DeleteModal
          itemName={deletingContract.name}
          confirm={() => deleteItem(deletingContract)}
          cancel={() => setDeletingContract(null)}
        />
      )}
    </Grid2>
  );
}
