import styled from 'styled-components'

export const Container = styled.div`
  color: #8a97b1;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  span.text {
    color: #8a97b1;
  }

  span.separator {
    color: #f28e00;
  }
`
