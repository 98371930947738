
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { FormControlLabel, FormGroup, Grid2, Switch } from "@mui/material";
import Header from "../../../components/Header";
import SelectEnterprise from "../../../components/SelectEnterprise";
import Input from "../../../components/Input";
import { Controller, useForm } from "react-hook-form";
import MultiSelect from "../../../components/MultiSelect";
import PageSize from "../../../components/Table/PageSize";
import Table from "../../../components/Table";
import Pagination from "../../../components/Table/Pagination";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import React, { SetStateAction, useEffect, useState } from "react";
import { getColumns } from "./colums";
import Button from "../../../components/Button";
import DeleteModal from "../../../components/DeleteModal";
import { IFiles } from "../types";
import file from "../../../api/file/file";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { ContinerSwitch } from "../../Channels/Channels/styles";
import apiCategory from "../../../api/category/apiCategory";
import CustomToast from '../../../components/Toast'; // Ajuste o caminho de importação conforme necessário
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface Category {
  id: number;
  name: string;
  active: boolean;
}

export default function CategoriesPage() {
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [numberOfPages, setNumberOfPages] = useState<number>(0);
  const [deletingFile, setDeletingFile] = useState<IFiles | null>(null);
  const [selectedValues, setSelectedValues] = useState([]);
  const [useDataFiles, setUseDataFiles] = useState<any>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [activeEdit, setActiveEdit] = useState<boolean>(false)
  const [isActive, setIsActive] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<any>(null);
  const [selectActive, setSelectActive] = useState();
  const [idCategory, setIdCategory] = useState()

  
  const schema = z.object({
    nameCategory: z.string().min(1, "Nome é obrigatório"),  
    isActive: z.boolean(),  
  });

  const notify = () => toast("Wow so easy!");

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<any>();

  const data = [
    { value: "ativos", label: "Ativos" },
    { value: "inativos", label: "Inativos" },
  ];

  const handleMultiSelectChange = (values: SetStateAction<never[]>) => {
    setSelectedValues(values);
  };

  // async function handleFilter(data: any) {
  //   if (activeEdit) {
  //     try {
  //       const response = await apiCategory.editCategory(data.nameCategory, data.isActive, idCategory);
  //       console.log("Categoria editada com sucesso", response);
  //       return response;
  //     } catch (error) {
  //       console.error('Erro ao atualizar categoria', error);
  //     }
  //   } else {
  //     try {
  //       const response = await apiCategory.postNewCategory(data.nameCategory);
  //       console.log("Nova categoria cadastrada com sucesso", response);
  //       return response;
  //     } catch (error) {
  //       console.error('Erro ao cadastrar nova categoria', error);
  //     }
  //   }
  // }
  async function handleFilter(data: any) {
    if (activeEdit) {
      try {
        const response = await apiCategory.editCategory(data.nameCategory, data.isActive, idCategory);
        listCategories(pageSize, currentPage);
        setOpenModal(false);
        setActiveEdit(false);
        toast.success("Categoria editada com sucesso!", {
          position: "top-center",
        });
        return response;
      } catch (error) {
        console.error("Erro ao atualizar categoria", error);
      }
    } else if (data.nameCategory) {
      try {
        const response = await apiCategory.postNewCategory(data.nameCategory);
        listCategories(pageSize, currentPage);
        setOpenModal(false);
        toast.success("Nova categoria cadastrada com sucesso!", {
          position: "top-center",
        });
        return response;
      } catch (error) {
        console.error("Erro ao cadastrar nova categoria", error);
      }
    } else {
      try {
        const { title, status } = data;
        const response = await apiCategory.getListCategory();
        const filteredData = (response as { Name: string; Active: boolean }[]).filter((item) => {
          const itemName = item.Name ?? "";
          const itemStatus = item.Active;
  
          const titleMatch = title
            ? itemName.toLowerCase().includes(title.toLowerCase())
            : true;
  
          const statusMatch = status && status.length > 0
            ? status.includes(itemStatus ? "ativos" : "inativos")
            : true;
  
          return titleMatch && statusMatch;
        });
  
        setUseDataFiles(filteredData);
      } catch (error) {
        console.error("Erro ao filtrar as categorias", error);
      }
    }
  }
  
  
  

  function resetAll() {
    setPageSize(10);
    setCurrentPage(1);
    setSelectedValues([]);
    reset();
    listCategories(10, 1); 
  }

  const editFile = (e:any) => {
    setOpenModal(true)
    setActiveEdit(true)
    setIdCategory(e.Id)
    reset({
      nameCategory: e.Name,  
      isActive: e.Active,    
    });
  };

  const showToast = () => {
    toast.success("Operação bem-sucedida!");
  };

  const toggleStatus = async (e: any) => {
    try {
      await apiCategory.editCategory(e.Name, e.Active, e.Id);
      listCategories(pageSize, currentPage)
    } catch (error) {
      console.error("Erro ao atualizar status:", error);
    }
  };

  async function listCategories(pageSize: number, currentPage: number) {
    try {
      const response = await apiCategory.getListCategory(selectActive)
      setUseDataFiles(response);
      return response;
    } catch (error) {
      console.error("Erro ao listar as categorias", error);
    }
  }

  async function deleteItem(deletingItem: any) {
    try {
      const response = await apiCategory.deleteCategory(deletingItem.Id)
      listCategories(pageSize, currentPage)
      return response
    } catch (error) {
      console.error('Erro ao deletar categoria', error)
    }
  }

  useEffect(() => {
    listCategories(pageSize, currentPage);
  }, [currentPage, pageSize]);

  const handleSelectedHotelChange = (selectedHotelId: number | null) => {
    setSelectActive(selectedHotelId)
  };

  useEffect(() => {
    listCategories()
    console.log("selectActive", selectActive)
  },[selectActive])


  return (
    <Grid2 container spacing={1} mt={5} ml={5} mr={1}>
      <div className="flex w-full">
        <div className="w-1/2">
          <Header
            title="Categorias"
            subTitle="Visualize e gerencie as categorias dos documentos disponibilizados."
            arrowIcon={false}
          />
        </div>
        <div className="flex flex-col items-end gap-5 w-1/2">
          <div>
            <SelectEnterprise onSelectedHotelChange={handleSelectedHotelChange} />
          </div>

          <div>
            <Button
              title="+ Nova categoria"
              color="#fff"
              backGroundColor="#F28E00"
              borderRadius={8}
              onClick={() => setOpenModal(true)} 
            />
          </div>
        </div>
      </div>

        {openModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
          onClick={() => { setOpenModal(false); setActiveEdit(false); }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              padding: "20px",
              borderRadius: "8px",
              width: "50%",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex justify-between items-center">
              <div>
                <p className="text-3xl font-bold text-[#404040]">
                  {activeEdit ? 'Editar categoria' : 'Cadastrar categoria'}
                </p>
              </div>
              <div>
                <IoIosCloseCircleOutline size={30} style={{ cursor: 'pointer' }} onClick={() => { setOpenModal(false); setActiveEdit(false); }} />
              </div>
            </div>
            <form className="pt-7">
              <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  <Input 
                    label="Nome"
                    type="text"
                    placeholder=""
                    register={register("nameCategory")}
                  />
                  {activeEdit && (
                  <div className="h-6">
                    <ContinerSwitch>
                     <Controller
                        name="isActive"  
                        control={control}  
                        defaultValue={selectedCategory?.Active || false}  
                        render={({ field }) => (
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Switch
                                  {...field}  
                                  checked={field.value}  
                                  onChange={(e) => field.onChange(e.target.checked)}  
                                />
                              }
                              label="Ativo"
                            />
                          </FormGroup>
                        )}
                      />
                      </ContinerSwitch>
                  </div>
                  )}
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end", gap: "16px", paddingTop: '24px' }}>
                <button
                  type="button"
                  onClick={() => { setOpenModal(false); setActiveEdit(false); }}
                  style={{
                    padding: "8px 16px",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                    color: "#8A97B1",
                    outline: "none",
                  }}
                >
                  Fechar
                </button>
                <button
                  type="submit"
                  onClick={handleSubmit(handleFilter)}
                  style={{
                    backgroundColor: "#F28E00",
                    color: "#fff",
                    padding: "8px 16px",
                    border: "none",
                    borderRadius: "8px",
                    cursor: "pointer",
                    outline: "none",
                  }}
                >
                  {activeEdit ? 'Salvar' : 'Adicionar'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      <div className="w-screen shadow-custom rounded-lg p-6">
        <form
          className="flex flex-col gap-6"
          onSubmit={handleSubmit(handleFilter)}
        >
          <div className="flex gap-6">
            <div className="w-2/4">
              <Input
                label="Nome"
                placeholder="Digite o nome da categoria"
                type="text"
                register={register("title")}
              />
            </div>
            <div className="w-2/4">
              <MultiSelect
                label="Status"
                data={data}
                onChange={handleMultiSelectChange}
                style={{ width: "100%" }}
                file={false}
                register={undefined}
                errors={undefined}
                message={undefined}
              />
            </div>
          </div>
          <div className="flex justify-end gap-4">
            <Button
              title="Limpar"
              color="#F28E00"
              backGroundColor="#FCFCFA"
              borderColor="#E3E7FF"
              borderRadius={8}
              onClick={resetAll}
              type="reset"
            />
            <Button
              title="Buscar"
              color="#fff"
              backGroundColor="#F28E00"
              borderRadius={8}
              onClick={handleSubmit(handleFilter)}
            />
          </div>
        </form>
      </div>

      <div className="flex flex-col gap-6 w-screen shadow-custom rounded-lg p-6">
        <div className="flex justify-between">
          <div className="flex flex-col gap-4 items-center">
          <div className="flex justify-between w-[100%]">
            <div className="text-[#6D7882] font-main">
              <p>
                <span className="font-bold font-main">
                  {useDataFiles?.length} </span> 
                  categorias cadastradas
              </p>
            </div>

          <div className="flex gap-4 items-center">
            <p className="text-[#6D7882]">Itens por página</p>
            <PageSize
                pageSize={pageSize}
                setPageSize={(size) => {
                  setPageSize(size);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
            <Table
              data={useDataFiles}
              columns={getColumns(
                editFile,
                setDeletingFile,
                false,
                toggleStatus
              )}
              hidePagination
            />

          </div>
        </div>
      </div>
      {deletingFile && (
        <DeleteModal
          itemName={deletingFile.Name}
          confirm={() => deleteItem(deletingFile)}
          cancel={() => setDeletingFile(null)}
        />
      )}
    </Grid2>
  );
}
