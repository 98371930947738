/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { z } from "zod";
import { SetStateAction, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import Header from "../../../../components/Header";
import Button from "../../../../components/Button";
import BreadcrumbsPage from "../../../../components/Breadcrumbs";
import Input from "../../../../components/Input";
import IconCirlceGreen from "../../../../assets/icons/circle-green-ligth.svg";
import IconCircleWhite from "../../../../assets/icons/circle-white.svg";
import pointsApi from "../../../../api/points/pointsApi";

enum inputType {
  ENTRY = 1,
  OUTPUT = 2,
}

enum typePoints {
  MANUAL = "MANUAL",
  MASS = "MASS",
}

enum typeUpload {
  UNITY = "UNITY",
  OWNER = "OWNER",
}

type Unit = {
  Number: string;
  RoomId: number;
  UserId: number;
};

type Owner  = {
  Id: number;
  Name: string;
};

export default function NewPoints() {
  const navigate = useNavigate();
  const location = useLocation();
  const isEdit = location.state?.isEdit;
  const dataEdit = location.state?.data;


  const [itemType, setItemType] = useState<inputType>();
  const [typePoint, setTypePoint] = useState<typePoints>();
  const [typeUploads, setTypeUploads] = useState<typeUpload>();
  const [units, setUnits] = useState<Unit[]>([]);
  const [owners, setOwners] = useState<Owner[]>([]);


  const newChannelCreateSchema = z.object({
    totalPoints: z.string().min(1, "Total de pontos é obrigatório"),
    unity: z.number().min(1, "Unidade é obrigatória"),
    ownerId: z.number().min(1, "Proprietário é obrigatório"),
    title: z.string().min(1, "Título é obrigatório"),
    detailing: z.string().min(1, "Detalhamento é obrigatório"),
  });

  type NewChannelsSchema = z.infer<typeof newChannelCreateSchema>;
  const [selectedValueInput, setSelectedValueInput] =
    useState<string>("Novo benefício");

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<NewChannelsSchema>({
    resolver: zodResolver(newChannelCreateSchema),
    defaultValues: {
      totalPoints: "",
      unity: 0,
      ownerId: 0,
      title: "",
      detailing: "",
    },
  });

  async function handleSubmitForm(data: NewChannelsSchema) {
    const totalPoints = Number(data.totalPoints);  
    const unity = Number(data.unity);
    const getUserId = localStorage.getItem('userId')
    const userId = Number(getUserId);

    if ( itemType ) {
      try {
        const response = await pointsApi.postNewPoint(totalPoints, itemType, unity, userId, data.title, data.detailing)
          console.log("resposne", response)
          navigate(-1)
          return response
        } catch (error) {
          console.error('Errro ao incluir pontos', error)
        }
      }
    }
  async function getUnit() {
    try {
      const response = await pointsApi.getUnity()
      setUnits(response);
      return response;
    } catch(error) {
      console.error('Erro ao listar unidades', error)
    }
  }

  async function getOwner() {
    try {
      const response: Owner[] = await pointsApi.getOwners(); 
      setOwners(response)
      return response
    } catch (error) {
      console.error('Erro ao listar proprietários', error)
    }
  }

  useEffect(() => {
    getUnit() 
    getOwner()
  },[])


  useEffect(() => {
    if (isEdit && dataEdit) {
      console.log('data edit', dataEdit)
      // setValue("name", dataEdit[0].name);
    }
  }, [isEdit, dataEdit, setValue]);

  const handleChange = (value: string) => {
    setSelectedValueInput(value);
  };

  return (
    <div className="flex flex-col justify-between h-screen pl-10 pt-12">
      <div>
        <div className="flex flex-col w-full gap-2">
          <div>
            <BreadcrumbsPage />
          </div>
          <div className="w-1/2">
            <Header
              title={isEdit ? "Editar benefício" : "Incluir pontos"}
              arrowIcon={true}
            />
          </div>
        </div>

        <div className="flex flex-col gap-6 w-full shadow-custom rounded-lg p-6">
          <form
            id="my-form"
            className="flex flex-col gap-6"
            onSubmit={handleSubmit(handleSubmitForm)}
          >
            <div className="flex gap-4 sm:flex-col">
              {/* <button
                className={` h-[40px] flex items-center gap-3 border ${
                  typePoint === typePoints.MANUAL
                    ? "border-[#B6BA3A]"
                    : "border-white"
                }`}
                type="button"
                onClick={() => setTypePoint(typePoints.MANUAL)}
              >
                {typePoint === typePoints.MANUAL ? (
                  <img src={IconCirlceGreen} />
                ) : (
                  <img src={IconCircleWhite} />
                )}{" "}
                Inclusão manual
              </button> */}

              {/* <button
                type="button"
                className={` h-[40px] flex items-center gap-3 border ${
                  typePoint === typePoints.MASS
                    ? "border-[#B6BA3A]"
                    : "border-white"
                }`}
                onClick={() => setTypePoint(typePoints.MASS)}
              >
                {typePoint === typePoints.MASS ? (
                  <img src={IconCirlceGreen} />
                ) : (
                  <img src={IconCircleWhite} />
                )}{" "}
                Inclusão em massa
              </button> */}
            </div>

            {typePoint === typePoints.MASS ? (
              <div>
                <div className="flex gap-6">
                  <button
                    className={`
                      h-[40px]
                      flex items-center gap-3 border ${
                        itemType === inputType.ENTRY
                        ? "border-[#B6BA3A]"
                        : "border-white"
                    }`}
                    type="button"
                    onClick={() => setItemType(inputType.ENTRY)}
                  >
                    {itemType === inputType.ENTRY ? (
                      <img src={IconCirlceGreen} />
                    ) : (
                      <img src={IconCircleWhite} />
                    )}{" "}
                    Inclusão por unidade
                  </button>

                  <button
                    type="button"
                    className={`
                      h-[40px]
                      flex items-center gap-3 border ${
                        itemType === inputType.OUTPUT
                        ? "border-[#B6BA3A]"
                        : "border-white"
                     }`}
                    onClick={() => setItemType(inputType.OUTPUT)}
                  >
                    {itemType === inputType.OUTPUT ? (
                      <img src={IconCirlceGreen} />
                    ) : (
                      <img src={IconCircleWhite} />
                    )}{" "}
                    Inclusão por proprietário
                  </button>
                </div>
              </div>
            ) : (
              <div className="flex flex-col gap-6">
                <div className="flex gap-6 font-main sm:flex-col ">
                  <div className="flex gap-6 w-2/4 sm:flex-col w-[100%]">
                    <Input
                      label="Total de pontos"
                      placeholder="Digite o total de pontos"
                      type="number"
                      register={register("totalPoints")}
                      errors={errors.totalPoints}
                    />
                  </div>
                  <div className="flex gap-6 w-2/4 sm:flex-col w-[100%] items-end">
                    <button
                      className={`
                        flex-[1_0_0]
                        h-[40px]
                        flex items-center gap-3 border ${
                          itemType === inputType.ENTRY
                          ? "border-[#B6BA3A]"
                          : "border-white"
                        }`}
                      type="button"
                      onClick={() => setItemType(inputType.ENTRY)}
                    >
                      {itemType === inputType.ENTRY ? (
                        <img src={IconCirlceGreen} />
                      ) : (
                        <img src={IconCircleWhite} />
                      )}{" "}
                      Entrada
                    </button>

                    <button
                      type="button"
                      className={`
                      flex-[1_0_0]
                      h-[40px]
                      flex items-center gap-3 border ${
                        itemType === inputType.OUTPUT
                        ? "border-[#B6BA3A]"
                        : "border-white"
                      }`}
                      onClick={() => setItemType(inputType.OUTPUT)}
                    >
                      {itemType === inputType.OUTPUT ? (
                        <img src={IconCirlceGreen} />
                      ) : (
                        <img src={IconCircleWhite} />
                      )}{" "}
                      Saída
                    </button>
                  </div>
                </div>
                <div className="flex gap-6 w-2/4 sm:flex-col w-[100%]">
                    <div className="flex flex-col w-1/2">
                      <label htmlFor="unityId" className="font-medium text-black pb-2">
                        Unidade
                      </label>
                      <select
                        id="unityId"
                        {...register("unity", { valueAsNumber: true })}
                        onChange={(e) => setValue("unity", Number(e.target.value))}
                        className={`border p-2 rounded ${
                          errors.unity ? "border-red-500" : "border-gray-300"
                        }`}
                      >
                        <option value={0} disabled>
                          Selecione uma unidade
                        </option>
                        {units.map((unit) => (
                          <option key={unit.RoomId} value={unit.RoomId}>
                            {unit.Number.trim()}
                          </option>
                        ))}
                      </select>
                      {errors.unity && (
                        <span className="text-red-500 text-sm">{errors.unity.message}</span>
                      )}
                    </div>
                  <div className="flex flex-col w-1/2">
                          <label htmlFor="ownerId" className="font-medium text-black pb-2">
                            Proprietário
                          </label>
                          <select
                            id="ownerId"
                            {...register("ownerId", { valueAsNumber: true })}
                            onChange={(e) => setValue("ownerId", Number(e.target.value))} 
                            className={`border p-2 rounded ${
                              errors.ownerId ? "border-red-500" : "border-gray-300"
                            }`}
                          >
                            <option value={0} disabled>
                              Selecione um proprietário
                            </option>
                            {owners.map((owner) => (
                              <option key={owner.Id} value={owner.Id}>
                                {owner.Name}
                              </option>
                            ))}
                          </select>
                          {errors.ownerId && (
                            <span className="text-red-500 text-sm">{errors.ownerId.message}</span>
                          )}
                        </div>
                </div>

                <div className="flex gap-6 w-2/4 sm:flex-col w-[100%]">
                  <Input
                    label="Título"
                    placeholder="Digite o título do lançamento no extrato de pontos"
                    type="text"
                    register={register('title')}
                    errors={errors.title}
                  />
                  <Input
                    label="Detalhamento"
                    placeholder="Digite o detalhamento para o extrato de pontos"
                    type="text"
                    register={register('detailing')}
                    errors={errors.detailing}
                  />
                </div>
              </div>
            )}
          </form>
        </div>
      </div>

      <div className="flex justify-between mt-auto shadow-custom p-10 sm:flex-col gap-6">
        <div>
          <Button
            title="Voltar"
            onClick={() => navigate(-1)}
            backGroundColor={"#FCFCFA"}
            color={"#F28E00"}
            borderRadius={8}
          />
        </div>
        <div className="flex gap-4 sm:flex-col">
          <Button
            title={isEdit ? "Salvar benefício" : "Cadastrar pontuação"}
            backGroundColor={"#F28E00"}
            color={"#FAFAFC"}
            borderRadius={8}
            type="submit"
            form="my-form"
          />
        </div>
      </div>
    </div>
  );
}
