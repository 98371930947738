import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import ActionsMenu from "../../../components/Table/ActionsMenu";
import { IFiles } from "../types";
import { ContinerSwitch } from "../../Channels/Channels/styles";
import React from "react";

interface IRow {
  Name: string;
  category: number;
  // Title: string; // Adiciona o campo Title para acesso no toggleStatus
}

export function getColumns(
  toggleIsEdit?: ((row: IFiles) => void) | null,
  deleteHotelChain?: ((row: IFiles) => void) | null,
  exist?: boolean,
  toggleStatus?: ((row: { Id: string; Active: boolean; Name: string }) => void) | null,
) {
  const columns = [
    {
      name: "Nome",
      selector: (row: IRow) => <p>{row.Name}</p>,
    },
    {
      name: "Status",
      selector: (row: {
        Active: boolean; Id: string; Name: string 
}) => {
        const handleChange = async () => {
          try {
            if (row && toggleStatus) {
              const newStatus = !row.Active;
              await toggleStatus({ Id: row.Id, Active: newStatus, Name: row.Name });
            }
          } catch (error) {
            console.error("Erro ao alternar o status:", error);
          }
        };

        return (
          <ContinerSwitch>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={row.Active}
                    edge="start"
                    onChange={handleChange}
                  />
                }
                label={row.Active ? "Ativo" : "Inativo"}
              />
            </FormGroup>
          </ContinerSwitch>
        );
      },
    },
    {
      width: "60px",
      cell: (row: IFiles) => (
        <ActionsMenu
          onEdit={toggleIsEdit ? () => toggleIsEdit(row) : null}
          onDelete={deleteHotelChain ? () => deleteHotelChain(row) : null}
        />
      ),
    },
  ];

  if (exist) {
    columns.splice(2, 0, {
      name: "Status",
      selector: (row: IRow) => (
        <FormGroup>
          {row.category === 1 ? (
            <FormControlLabel
              control={<Switch defaultChecked />}
              label="Ativo"
              disabled
            />
          ) : (
            <FormControlLabel
              control={<Switch defaultChecked />}
              label="Inativo"
              disabled
            />
          )}
        </FormGroup>
      ),
    });
  }

  return columns;
}
