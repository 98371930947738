import styled from "styled-components";

export const ContinerSwitch = styled.div`
    padding: 6px;
    border-radius: 4px;
    border: 1px solid #B6BA3A;
    background: #FCFDF8;

    .css-19opgx6-MuiFormControlLabel-root .MuiFormControlLabel-label {
        font-weight: bold!important;
        color: #404040!important;
    }
    .css-17jyosd-MuiSwitch-thumb {
        width: 10px;
        height: 10px;
        margin-top: 50%;
        color: white;
    }
    .css-161ms7l-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
        background: #B6BA3A;
    }

    .css-161ms7l-MuiButtonBase-root-MuiSwitch-switchBase {
        left: 0px!important;
    }
    .css-18fwylm-MuiSwitch-root {
        width: 50px!important;
        /* height: 50px; */
        margin-left: 0px!important;
    }

`

